import { makeAutoObservable, runInAction } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import {
  alertLevelsDefaults,
  emptyUsers,
  UsersPageSize,
} from '../../helper/constants'
import { UserStatus, UserType } from '../../helper/enum'
import utilities from '../../helper/utilities'
import agent from '../api/agent'
import { PageOptions, Users } from '../interfaces/admin'
import { UserData } from '../interfaces/auth'
import { Row, Rows } from '../interfaces/common'
import { UpdateUserRequest } from '../interfaces/user'

export default class UserStore {
  users: Users | undefined = undefined
  selectedUser: UserData | undefined
  usersOptions: PageOptions = {
    ...alertLevelsDefaults,
    PageSize: UsersPageSize,
  }
  isLoading = true

  constructor() {
    makeAutoObservable(this)

    makePersistable(this, {
      name: 'UserStore',
      properties: ['users', 'usersOptions'],
      storage: window.sessionStorage,
    })
  }

  loadUsers = async (options: PageOptions | undefined = undefined) => {
    try {
      options = options || { ...alertLevelsDefaults, PageSize: UsersPageSize }
      const result = await agent.WAUsers.getUsers({ params: options })

      this.setUsers(result)
    } catch (error) {
      this.setUsers(emptyUsers)
    } finally {
      this.setIsLoading(false)
    }
  }

  setUsers = (users: Users) => {
    this.users = users
  }

  setSelectedUser = (id: string) => {
    const selectedUser = this.users?.data.find((user) => user.id === id)
    this.selectedUser = selectedUser
  }

  setUsersOptions = (options: PageOptions) => {
    this.usersOptions = options
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  getTableRows: () => Rows = () => {
    const newUsers: Rows = { rows: [] }

    if (this.users) {
      this.users.data.forEach((user) => {
        const row: Row = { id: user.id, data: [] }
        row.data.push(utilities.getTextAddOn(user.firstName))
        row.data.push(utilities.getTextAddOn(user.lastName))
        row.data.push(
          utilities.getTextAddOn(
            user.status ? UserStatus.Active : UserStatus.Inactive
          )
        )
        row.data.push(
          utilities.getTextAddOn(
            user.type === UserType.Public.toLowerCase()
              ? UserType.Public
              : UserType.Confidential
          )
        )
        row.data.push(utilities.getTextAddOn(user.roles.join('\n')))
        row.data.push({
          addOnContent: utilities.getOpenModalAddOn('Edit'),
          addOnOnClick: (id: string) => {
            this.setSelectedUser(id)
          },
        })
        newUsers.rows.push(row)
      })
    }

    return newUsers
  }

  updateSelectedUserInfo = (user: UserData) => {
    this.selectedUser = user
  }

  saveSalectedUser = async () => {
    if (this.selectedUser) {
      const updateUserRequest: UpdateUserRequest = {
        id: this.selectedUser.id,
        firstName: this.selectedUser.firstName,
        lastName: this.selectedUser.lastName,
        mobileNumber: this.selectedUser.mobileNumber,
        status: this.selectedUser.status,
        skipVideo: this.selectedUser.skipVideo,
        skipTutorial: this.selectedUser.skipTutorial,
        type: this.selectedUser.type,
        email: undefined,
        password: undefined,
        roles: undefined,
      }

      await agent.WAUsers.updateUser(updateUserRequest)

      runInAction(() => {
        if (this.users) {
          this.users.data = this.users.data.map((user) => {
            if (user.id === this.selectedUser?.id) {
              return this.selectedUser
            }
            return user
          })
        }
      })
    }
  }
}
