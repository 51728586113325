import { BellIcon } from '../UI'
import { MeasurementType } from '../../helper/enum'
import {
  LevelDetails,
  SitesWithAlertLevel,
} from '../../services/interfaces/map'
import SubscriptionItem from './SubscriptionItem'
import {
  NotificationTypeManualSitesMap,
  NotificationTypeMap,
} from '../../helper/constants'

export interface IProps {
  data: SitesWithAlertLevel
  activeLevel: string
  hasSubscription: (id: string) => boolean
  isActiveSubscription: (type: string) => boolean
  setActiveLevel: (data: string) => void
  updateState: (newNotification: string) => void
}

const AlertTypeWaterLevel: React.FC<IProps> = ({
  activeLevel,
  data,
  hasSubscription,
  isActiveSubscription,
  setActiveLevel,
  updateState,
}) => {
  const {
    alertLevels,
    measurementType,
    name,
    isAutomated,
    measurementUnit,
    description,
  } = data

  return (
    <div className="marker-info-wrapper">
      <div className="text-wa">{name}</div>
      <div className="mt-2 leading-5 text-sm">
        {isAutomated ? measurementType : 'Community Notification'}
      </div>
      <div className="mt-2 leading-5 max-w-md text-center">
        {isAutomated ? '' : description}
      </div>
      <div className="wa-evenly-flex mt-8">
        {alertLevels
          .sort((a, b) => a.level - b.level)
          .sort((a, b) => b.measurementType.localeCompare(a.measurementType))
          .map((alert: LevelDetails) => {
            const { id, measurementType, level, name } = alert
            const isAlertActive = activeLevel === id
            return (
              <div key={id} className="level-wrapper">
                <>
                  {hasSubscription(id) && <BellIcon className="notif-bell" />}
                  <div
                    className={`level-info-wrapper ${
                      isAlertActive ? 'alert-level-active' : ''
                    }`}
                    onClick={() => setActiveLevel(id)}
                  >
                    <span
                      className={`level-info ${
                        measurementType === MeasurementType.WaterLevel
                          ? 'wl-text-size'
                          : ''
                      }`}
                    >
                      {isAutomated ? (
                        <>
                          {measurementType}
                          <br />
                          {level}
                          <br />
                          {measurementUnit}
                        </>
                      ) : (
                        name
                      )}
                    </span>
                  </div>
                  {isAlertActive && (
                    <>
                      <div className="shadow-active-level-indicator"></div>
                      <div className="active-level-indicator"></div>
                    </>
                  )}
                </>
              </div>
            )
          })}
      </div>
      <div
        className={`notif-type-wrapper  ${
          isAutomated ? 'justify-between' : 'justify-center'
        }`}
      >
        {Array.from(
          isAutomated
            ? NotificationTypeMap.entries()
            : NotificationTypeManualSitesMap.entries()
        ).map(([key, value]) => {
          return (
            <SubscriptionItem
              key={key}
              name={value}
              isActive={isActiveSubscription(key)}
              onClick={() => updateState(key)}
              className="w-18"
            />
          )
        })}
      </div>
    </div>
  )
}

export default AlertTypeWaterLevel
