import { AxiosRequestHeaders } from 'axios';

import useAuth from "../hooks/useAuth"
import { MapCoordinates } from '../services/interfaces/map';

export const getAuthorisationToken = (): AxiosRequestHeaders | null => {
    const userContext = useAuth()
    if (userContext.user !== null) {
        const token = userContext.user.token

        return {
                'Authorization': `Bearer ${token}`
            }
    }

    return null
}

export const getMapCoordinates = (coordinates: string): MapCoordinates => {
    let latitude = 0
    let longitude = 0

    if (coordinates) {
        const coordinatesArray = coordinates.split(',')
        latitude = +coordinatesArray[0]
        longitude = +coordinatesArray[1]
    }

    return {
        lat: latitude,
        lng: longitude
    }
}