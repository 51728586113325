import { useFormik } from 'formik'
import { AxiosResponse } from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import toast from 'react-hot-toast'

import { ErrorCodes, PageLinks } from '../../helper/enum'
import { FormInput, FormButton } from '../UI'
import { RegisterFormData, registerSchema, RegisterUserData } from './schema'
import { RegistrationSuccessMessage } from '../../helper/constants'
import { ServerErrors } from '../../services/interfaces/common'

interface IProps {
  onSubmit: (values: RegisterUserData) => Promise<AxiosResponse>
}

const Register: React.FC<IProps> = ({ onSubmit }) => {
  const navigate = useNavigate()
  const recaptchaKey = process.env.REACT_APP_GOOGLE_CAPTCHA_KEY ?? ''

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldError,
  } = useFormik<RegisterFormData>({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      mobileNumber: '',
      password: '',
      isVerified: false,
    },
    validationSchema: registerSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (formData: RegisterFormData, { setSubmitting }) => {
      const userData: RegisterUserData = formData

      await onSubmit(userData)
        .then(() => {
          toast.success(RegistrationSuccessMessage)
          navigate(`${PageLinks.RegisterSuccess}?email=${userData.email}`)
        })
        .catch((error) => handleErrors(error))
        .finally(() => setSubmitting(false))
    },
  })

  const handleErrors = (errors: ServerErrors) => {
    if (Array.isArray(errors?.error)) {
      errors?.error.forEach((err) => {
        if (err.code === ErrorCodes.DuplicateEmail) {
          setFieldError('email', err.description)
        } else {
          toast.error(err.description)
        }
      })
    } else {
      const errorMessage: string = errors?.error
      toast.error(errorMessage)
    }
  }

  return (
    <div className="p-5">
      <div className="container">
        <div className=" p-5 w-full">
          <div className="max-w-md mx-auto">
            <form onSubmit={handleSubmit}>
              <div className="py-4 px-8">
                <div className="mb-4">
                  <FormInput
                    className="form-input"
                    id="email"
                    type="email"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={touched.email ? errors.email : ''}
                  />
                </div>
                <div className="flex mb-4">
                  <div className="w-1/2 mr-1">
                    <FormInput
                      className="form-input"
                      id="firstName"
                      type="text"
                      placeholder="First name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      error={touched.firstName ? errors.firstName : ''}
                    />
                  </div>
                  <div className="w-1/2 ml-1">
                    <FormInput
                      className="form-input"
                      id="lastName"
                      type="text"
                      placeholder="Last name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      error={touched.lastName ? errors.lastName : ''}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <FormInput
                    className="form-input"
                    id="mobileNumber"
                    type="text"
                    placeholder="Mobile number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mobileNumber}
                    error={touched.mobileNumber ? errors.mobileNumber : ''}
                  />
                </div>
                <div className="mb-12">
                  <FormInput
                    className="form-input"
                    id="password"
                    type="password"
                    placeholder="Create a password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={touched.password ? errors.password : ''}
                  />
                  <p className="text-grey text-xs mt-1 text-justify">
                    Use 8 or more characters with a mix of letters, numbers
                    &amp; symbols
                  </p>
                </div>
                <div className="flex justify-center mb-4">
                  <ReCAPTCHA
                    sitekey={recaptchaKey}
                    onChange={() => setFieldValue('isVerified', true)}
                    className="custom-captcha"
                  />
                </div>
                <div className="text-center">
                  <p className="text-info">
                    Already have an account?{' '}
                    <Link to="/login" className="underline hover:text-black">
                      Log in here
                    </Link>
                  </p>
                  <div className="mt-8">
                    <FormButton type="submit" className="btn-primary">
                      Register
                    </FormButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
