export enum HTTP_Status {
  Idle = 'idle',
  Fulfilled = 'fullfilled',
  Loading = 'loading',
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}

export enum ErrorCodes {
  DuplicateEmail = 'DuplicateEmail',
  NoDataFound = 'HT404',
}

export enum ErrorMessages {
  HistoryNoDataFound = 'No alerts history found.',
}

export enum PageLinks {
  About = '/about',
  Alert = '/alert',
  ConfirmEmail = '/confirm',
  FAQ = '/faq',
  ForgotPassword = '/forgot-password',
  History = '/history',
  IntroVideo = '/intro-video',
  Login = '/login',
  Profile = '/profile',
  Register = '/register',
  RegisterSuccess = '/registration-success',
  ResetPassword = '/reset',
  TermsAndConditions = '/terms-conditions',
  Maintenance = '/maintenance',
  AdminCommunicate = '/admin/communicate',
  AdminTemplates = '/admin/templates',
  AdminHistory = '/admin/history',
  AdminUsers = '/admin/users',
  AdminSendNotification = '/admin/send-notification',
  AdminEditTemplate = '/admin/template/edit',
  Site = '/admin/site',
}

export enum EmailTypes {
  Confirm = 'confirm',
  Forgot = 'forgot',
}

export enum ButtonVariant {
  Primary,
  Secondary,
}

export enum MeasurementType {
  WaterLevel = 'Water Level',
  Spilling = 'Spilling',
  Flow = 'Flow Rate',
  Riparian = 'Riparian',
}

export enum UserDataChange {
  Name,
  MobileNumber,
  Password,
}

export enum HeaderName {
  Alert = 'Alert',
  Profile = 'Profile',
  History = 'History',
  About = 'About',
  AdminCommunicate = 'Communicate',
  AdminTemplates = 'Templates',
  AdminHistory = 'History',
  AdminUsers = 'Users',
  Site = 'Site',
}

export enum UserRoles {
  admin = 'admin',
  customer = 'customer',
}

export enum UserType {
  Public = 'Public',
  Confidential = 'Confidential',
}

export enum SiteType {
  Public = 'Public',
  Confidential = 'Confidential',
}

export enum UserStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum SiteStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum AutomatedSite {
  True = 'True',
  False = 'False',
}

export enum TableSortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}
