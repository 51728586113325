import { FC } from 'react'
import { PageLinks } from '../../helper/enum'
import { IPropsHeaderList } from './HeaderList'
import LinkItem from './LinkItem'
interface IProps {
  isFlyoutMenu: boolean
  header: IPropsHeaderList
  page: PageLinks
  onClose?: () => void
}
const LinkItems: FC<IProps> = ({ isFlyoutMenu, header, page, onClose }) => {
  const listItems = header.list.map((item, index) => {
    return (
      <LinkItem
        key={index}
        navigateToPage={item.pageLink}
        currentPage={page}
        linkText={item.text}
        className={isFlyoutMenu ? 'flyout-links' : 'header-item pl-8'}
        onClose={onClose}
      />
    )
  })
  return <>{listItems}</>
}

export default LinkItems
