import { ConfirmEmailData, ResendEmailData, ResetPassData, UserData } from './interfaces/auth'
import { confirmEmailUrl, loginUserUrl, registerUserUrl, resendEmailUrl, resetPassUrl } from '../helper/constants'
import { LoginUserData } from './../components/Login/schema';
import { RegisterUserData } from '../components/Register/schema'
import axiosClient from './apiClient'

const registerUser = (userData: RegisterUserData) => {
    const { firstName, lastName, email, password, mobileNumber } = userData;
    return axiosClient.post(registerUserUrl, {
        firstName, lastName, email, password, mobileNumber
    })
    .then((data) => {
        return data
    })
    .catch((error) => {
        throw error.response.data
    })
}

const resendRegistrationEmail = (emailInfo: ResendEmailData) => {
    return axiosClient.post(resendEmailUrl, emailInfo)
    .then((data) => {
        return data
    })
    .catch((error) => {
        throw error.response.data
    })
}

const loginUser = (userData: LoginUserData) => {
    return axiosClient.post(loginUserUrl, userData)
    .then((data) => {
        return data
    })
    .catch((error) => {
        throw error.response.data
    })
}

const confirmEmail = (userData: ConfirmEmailData) => {
    return axiosClient.post<UserData>(confirmEmailUrl, userData)
    .then((response) => {
        return response.data
    })
    .catch((error) => {
        throw error.response.data
    })
}

const forgotPass = (emailInfo: ResendEmailData) => {
    return axiosClient.post(resendEmailUrl, emailInfo)
    .then((data) => {
        return data
    })
    .catch((error) => {
        throw error.response.data
    })
}

const resetPass = (resetInfo: ResetPassData) => {
    return axiosClient.post(resetPassUrl, resetInfo)
    .then((data) => {
        return data
    })
    .catch((error) => {
        throw error.response.data
    })
}

const AuthService = {
    confirmEmail,
    forgotPass,
    loginUser,
    registerUser,
    resendRegistrationEmail,
    resetPass
}

export default AuthService