import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Header } from '../../../components'
import { AdminPortal } from '../../../components/Header/HeaderList'
import { Loading, Modal, Table } from '../../../components/UI'
import {
  HistoriesTableColumn,
  HistoryPageSize,
} from '../../../helper/constants'
import { HeaderName, PageLinks } from '../../../helper/enum'
import { Pages, TableColumnType } from '../../../services/interfaces/admin'
import { useStore } from '../../../services/stores/store'

const AdminHistoryPage = () => {
  const { historyStore } = useStore()
  const {
    histories,
    historyOptions,
    isLoading,
    selectedHistory,
    isNotificationModal,
    loadHistories,
    setIsNotificationModal,
    setIsSubscribersModal,
    setSelectedHistory,
    setHistoryOptions,
    getTableRows,
  } = historyStore

  const columns: TableColumnType[] = HistoriesTableColumn
  const pages: Pages = {
    currentPage: histories?.currentPage || 1,
    totalPage: histories?.totalPage || 1,
    pageSize: HistoryPageSize,
    options: historyOptions,
    setPageOptions: setHistoryOptions,
  }

  const getModalHeader = () => {
    if (!selectedHistory) {
      return <></>
    }

    return (
      <div>
        <h5
          className="text-xl font-medium leading-normal text-white w-full"
          id="ht-modal-label"
        >
          {selectedHistory.site}
        </h5>
        <p className="text-sm font-medium text-white w-full">
          {selectedHistory.alertLevel}
        </p>
        <p className="text-sm font-medium text-white w-full">{`Sent on: ${selectedHistory.createdDate
          .replace('T', '  ')
          .substring(0, 20)}`}</p>
      </div>
    )
  }

  const getModalBody = () => {
    if (!selectedHistory) {
      return <></>
    }

    if (isNotificationModal) {
      return <p>{selectedHistory.content}</p>
    } else {
      return (
        <>
          <h5 className="font-medium underline">Subscribers:</h5>
          <ul className="mt-2">
            {selectedHistory.recipent.map((subscriber, index) => {
              return (
                <li key={index} className="font-extralight">
                  {subscriber}
                </li>
              )
            })}
          </ul>
        </>
      )
    }
  }

  const handleClose = () => {
    setSelectedHistory('')
    setIsNotificationModal(false)
    setIsSubscribersModal(false)
  }

  useEffect(() => {
    loadHistories(historyOptions)
  }, [historyOptions])

  if (isLoading) {
    return (
      <div className="h-screen">
        <Loading />
      </div>
    )
  }

  return (
    <div>
      <Header
        page={PageLinks.AdminHistory}
        header={AdminPortal}
        currentPageName={HeaderName.AdminHistory}
      />
      <div className="flex flex-col mb-5">
        <span className="mt-20"></span>
      </div>
      <Table columns={columns} rows={getTableRows()} pages={pages} />
      <Modal
        header={getModalHeader()}
        body={getModalBody()}
        onClose={handleClose}
      ></Modal>
    </div>
  )
}

export default observer(AdminHistoryPage)
