import { HeaderName, PageLinks } from '../../helper/enum'

export interface IPropsHeaderList {
  isFrontEnd: boolean
  list: { pageLink: PageLinks; text: HeaderName }[]
}
export const FrontEnd: IPropsHeaderList = {
  isFrontEnd: true,
  list: [
    {
      pageLink: PageLinks.Alert,
      text: HeaderName.Alert,
    },
    {
      pageLink: PageLinks.Profile,
      text: HeaderName.Profile,
    },
    {
      pageLink: PageLinks.History,
      text: HeaderName.History,
    },
    {
      pageLink: PageLinks.About,
      text: HeaderName.About,
    },
  ],
}

export const AdminPortal: IPropsHeaderList = {
  isFrontEnd: false,
  list: [
    {
      pageLink: PageLinks.AdminCommunicate,
      text: HeaderName.AdminCommunicate,
    },
    {
      pageLink: PageLinks.AdminTemplates,
      text: HeaderName.AdminTemplates,
    },
    {
      pageLink: PageLinks.AdminHistory,
      text: HeaderName.AdminHistory,
    },
    {
      pageLink: PageLinks.AdminUsers,
      text: HeaderName.AdminUsers,
    },
    {
      pageLink: PageLinks.Site,
      text: HeaderName.Site,
    },
  ],
}

export enum headerButtonList {
  ViewWebsite = 'View Website',
  AdminPortal = 'Admin Portal',
}
