import toast from 'react-hot-toast'

import AuthService from '../../services/AuthService'
import RegistrationSuccess from '../../components/RegistrationSuccess/RegistrationSuccess'
import { EmailTypes } from '../../helper/enum'
import { getQueryParameter } from '../../helper/urlHelper'
import { RegistrationResendSuccessMessage } from '../../helper/constants'
import { ResendEmailData } from '../../services/interfaces/auth'

const RegistrationSuccessPage = () => {
  const email = getQueryParameter('email')

  const handleResendClick = async () => {
    const resendRegData: ResendEmailData = {
      email: email ?? '',
      type: EmailTypes.Confirm.toString(),
    }
    AuthService.resendRegistrationEmail(resendRegData)
      .then(() => {
        toast.success(RegistrationResendSuccessMessage)
      })
      .catch((err) => {
        toast.error(err.error)
      })
  }
  return (
    <div className="relative">
      <div className="default-bg register-success-bg">
        <RegistrationSuccess onResendClick={handleResendClick} />
      </div>
    </div>
  )
}

export default RegistrationSuccessPage
