import { AxiosRequestHeaders } from 'axios'
import { useEffect, useState } from 'react'
import {
  incorrectMobileNumberError,
  mobileNumberEmptyError,
  nameEmptyError,
  nameWithSpecialCharactersError,
  passwordEmptyError,
  passwordMinimumRequirementsError,
  ResetPasswordSuccessMessage,
} from '../../helper/constants'
import { UserDataChange } from '../../helper/enum'
import { getAuthorisationToken } from '../../helper/mapHelper'
import useAuth from '../../hooks/useAuth'
import UserService from '../../services/UserService'
import { UserData } from '../../services/interfaces/auth'
import toast from 'react-hot-toast'

const useProfile = () => {
  const authContext = useAuth()

  const [error, setError] = useState('')
  const [isActive, setIsActive] = useState<UserDataChange | null>(null)
  const [mobileNumber, setMobileNumber] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [user, setUser] = useState<UserData | null>(null)

  const authToken: AxiosRequestHeaders | null = getAuthorisationToken()
  const userId = authContext?.user?.id

  const handleNameChange = () => {
    if (name && name !== '') {
      const result = /^[a-zA-Z ]+$/.test(name)

      if (result && authToken && userId) {
        UserService.updateUserName(authToken, userId, name).then(() => {
          setIsActive(null)
          setError('')
        })
      } else {
        setError(nameWithSpecialCharactersError)
      }
    } else if (name === '') {
      setError(nameEmptyError)
    }
  }

  const handleMobileNumberChange = () => {
    if (mobileNumber !== '') {
      const result =
        /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/.test(
          mobileNumber
        )

      if (result && authToken && userId) {
        UserService.updateUserMobileNumber(
          authToken,
          userId,
          mobileNumber
        ).then(() => {
          setIsActive(null)
          setError('')
        })
      } else {
        setError(incorrectMobileNumberError)
      }
    } else if (mobileNumber === '') {
      setError(mobileNumberEmptyError)
    }
  }

  const handlePasswordChange = () => {
    if (password === '') {
      setError(passwordEmptyError)
    } else {
      const result =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          password
        )

      if (result && authToken && userId) {
        UserService.updateUserPassword(authToken, userId, password).then(() => {
          setIsActive(null)
          setError('')
          toast.success(ResetPasswordSuccessMessage)
        })
      } else {
        setError(passwordMinimumRequirementsError)
      }
    }
  }

  const handleCancel = () => {
    setError('')
    setIsActive(null)
  }

  useEffect(() => {
    if (authToken) {
      const userId = authContext?.user?.id

      if (userId) {
        UserService.fetchUser(authToken, userId).then((response) => {
          const userData = response.data
          setUser(userData)
          setName(userData.firstName + ' ' + userData.lastName)
          setMobileNumber(userData.mobileNumber)
        })
      }
    }
  }, [])

  return {
    error,
    isActive,
    mobileNumber,
    name,
    password,
    user,
    handleCancel,
    handleMobileNumberChange,
    handleNameChange,
    handlePasswordChange,
    setError,
    setIsActive,
    setMobileNumber,
    setName,
    setPassword,
  }
}

export default useProfile
