import { FC, TouchEvent, useEffect, useRef } from 'react'
import { PageLinks } from '../../helper/enum'
import Icons from '../../resources'
import HeaderButton from '../UI/Button/HeaderButton'
import { headerButtonList, IPropsHeaderList } from './HeaderList'
import LinkItems from './LinkItems'

interface IProps {
  page: PageLinks
  className?: string
  header: IPropsHeaderList
  admin: boolean
  onClose: () => void
  onSignout: () => void
}

const FlyoutMenu: FC<IProps> = ({
  page,
  className,
  header,
  admin,
  onClose,
  onSignout,
}) => {
  const ref = useRef<HTMLElement>(null)
  useEffect(() => {
    document.addEventListener('mousedown', Clickout)
    return () => {
      document.removeEventListener('mousedown', Clickout)
    }
  }, [])

  const Clickout = (eve: TouchEvent | MouseEvent) => {
    if (ref.current && !ref.current.contains(eve.target as HTMLElement)) {
      onClose()
    }
  }

  return (
    <aside ref={ref} className={`flyout ${className}`}>
      <div className="ml-3 mt-3">
        <strong onClick={() => onClose()} className="cursor-pointer">
          <img src={Icons.Close} className="w-10"></img>
        </strong>
      </div>
      <nav>
        <div className="w-full" id="menu">
          <ul className="pt-5 flex flex-col ml-10">
            <LinkItems
              isFlyoutMenu={true}
              header={header}
              page={page}
              onClose={onClose}
            />
          </ul>
        </div>
      </nav>
      <div className="flyout-footer">
        {admin && (
          <HeaderButton
            text={
              header.isFrontEnd
                ? headerButtonList.AdminPortal
                : headerButtonList.ViewWebsite
            }
            navigateTo={
              header.isFrontEnd ? PageLinks.AdminCommunicate : PageLinks.Alert
            }
            onClick={onClose}
          />
        )}
        <div onClick={onSignout} className="pt-5 hover:cursor-pointer">
          Sign out
        </div>
      </div>
    </aside>
  )
}

export default FlyoutMenu
