import { IconType } from './types'

const InfoIcon: React.FC<IconType> = ({ colour, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      {...props}
    >
      <path
        d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zm0 2.2a8.8 8.8 0 110 17.6 8.8 8.8 0 010-17.6zM9.9 5.5v6.6h2.2V5.5H9.9zm0 8.8v2.2h2.2v-2.2H9.9z"
        fill={colour}
      />
    </svg>
  )
}

export default InfoIcon
