import { useLocation, Navigate, Outlet } from 'react-router-dom'

import { checkTokenExpiration } from '../../helper/authHelper'
import { PageLinks } from '../../helper/enum'
import useAuth from '../../hooks/useAuth'

const RequireAuth = () => {
  const authContext = useAuth()
  const location = useLocation()

  const userToken = authContext.user?.token ?? ''
  const isTokenExpired = checkTokenExpiration(userToken)

  if (isTokenExpired) {
    authContext.setUser(null)
  }

  return authContext?.user ? (
    <Outlet />
  ) : (
    <Navigate to={PageLinks.Login} state={{ from: location }} replace />
  )
}

export default RequireAuth
