import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import {
  alertLevelsDefaults,
  emptyHistories,
  HistoryPageSize,
} from '../../helper/constants'
import utilities from '../../helper/utilities'
import agent from '../api/agent'
import { Histories, HistoryData, PageOptions } from '../interfaces/admin'
import { Row, Rows } from '../interfaces/common'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

export default class HistoryStore {
  histories: Histories | undefined = undefined
  selectedHistory: HistoryData | undefined
  historyOptions: PageOptions = {
    ...alertLevelsDefaults,
    PageSize: HistoryPageSize,
  }
  isLoading = true
  isSubscribersModal = false
  isNotificationModal = false

  constructor() {
    makeAutoObservable(this)

    makePersistable(this, {
      name: 'HistoryStore',
      properties: ['histories', 'historyOptions'],
      storage: window.sessionStorage,
    })
  }

  loadHistories = async (options: PageOptions | undefined = undefined) => {
    try {
      options = options || { ...alertLevelsDefaults, PageSize: HistoryPageSize }
      const result = await agent.HistoryList.getHistories({ params: options })

      this.setHistories(result)
    } catch (error) {
      this.setHistories(emptyHistories)
    } finally {
      this.setIsLoading(false)
    }
  }

  setHistories = (histories: Histories) => {
    this.histories = histories
  }

  setSelectedHistory = (id: string) => {
    const selectedHistory = this.histories?.data.find(
      (history) => history.id === id
    )
    this.selectedHistory = selectedHistory
  }

  setHistoryOptions = (options: PageOptions) => {
    this.historyOptions = options
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  setIsSubscribersModal = (isSubscribersModal: boolean) => {
    this.isSubscribersModal = isSubscribersModal
  }

  setIsNotificationModal = (isNotificationModal: boolean) => {
    this.isNotificationModal = isNotificationModal
  }

  getTableRows: () => Rows = () => {
    const newHistories: Rows = { rows: [] }

    if (this.histories) {
      this.histories.data.forEach((history) => {
        const row: Row = { id: history.id, data: [] }
        row.data.push(utilities.getTextAddOn(history.createdByUser))
        row.data.push(
          utilities.getTextAddOn(
            history.createdDate.replace('T', '  ').substring(0, 20)
          )
        )
        row.data.push(utilities.getTextAddOn(history.site))
        row.data.push(utilities.getTextAddOn(history.alertLevel))
        row.data.push({
          addOnContent: utilities.getOpenModalAddOn(
            `${history.recipent.length} subscriber(s)`
          ),
          addOnOnClick: (id: string) => {
            this.setSelectedHistory(id)
            this.setIsSubscribersModal(true)
            this.setIsNotificationModal(false)
          },
        })
        row.data.push({
          addOnContent: utilities.getIconAddOn(faEnvelope),
          addOnOnClick: (id: string) => {
            this.setSelectedHistory(id)
            this.setIsNotificationModal(true)
            this.setIsSubscribersModal(false)
          },
        })

        newHistories.rows.push(row)
      })
    }

    return newHistories
  }
}
