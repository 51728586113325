import { createContext, useContext } from 'react'
import AlertLevelStore from './alertLevelStore'
import CommonStore from './commonStore'
import NotificationStore from './notificationStore'
import UserStore from './userStore'
import TemplateStore from './templateStore'
import HistoryStore from './historyStore'
import SiteStore from './siteStore'

interface Store {
  alertLevelStore: AlertLevelStore
  commonStore: CommonStore
  notificationStore: NotificationStore
  userStore: UserStore
  templateStore: TemplateStore
  historyStore: HistoryStore
  siteStore: SiteStore
}

export const store: Store = {
  alertLevelStore: new AlertLevelStore(),
  commonStore: new CommonStore(),
  notificationStore: new NotificationStore(),
  userStore: new UserStore(),
  templateStore: new TemplateStore(),
  historyStore: new HistoryStore(),
  siteStore: new SiteStore(),
}

export const StoreContext = createContext(store)

export function useStore() {
  return useContext(StoreContext)
}
