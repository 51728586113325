import { AxiosResponse } from 'axios'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useState } from 'react'
import { ForgotPasswordResendSuccessMessage } from '../../helper/constants'
import { forgotPasswordScehma, ForgotPasswordData } from './schema'
import { FormButton } from '../UI'
import FormInput from '../UI/Form/Input'

interface IProps {
  onSubmit: (values: ForgotPasswordData) => Promise<AxiosResponse>
}

export const ForgotPassword: React.FC<IProps> = ({ onSubmit }) => {
  const [error, setError] = useState<string>('')
  const [isResendSuccess, setIsResendSuccess] = useState<boolean>(false)
  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik<ForgotPasswordData>({
      initialValues: {
        email: '',
      },
      validationSchema: forgotPasswordScehma,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: async (formData: ForgotPasswordData, { setSubmitting }) => {
        const userData: ForgotPasswordData = formData
        await onSubmit(userData)
          .then(() => {
            setError('')
            setIsResendSuccess(true)
          })
          .catch((error) => setError(error?.error))
          .finally(() => setSubmitting(false))
      },
    })

  return (
    <div className="p-5">
      <div className="container">
        <div className="absolute p-5 w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          {isResendSuccess ? (
            <div className="text-center">
              <p className="text-info mt-8 break-words font-semibold">
                {ForgotPasswordResendSuccessMessage}
              </p>
            </div>
          ) : (
            <div className="max-w-md mx-auto">
              <div className="text-center">
                <h1 className="text-4xl">Trouble logging in?</h1>
                <p className="text-info mt-8 break-words">
                  Enter your email address and we{"'"}ll send you a link to get
                  back into your account.
                </p>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="py-4 px-8">
                  <div className="mb-4">
                    <FormInput
                      className="form-input"
                      id="email"
                      type="email"
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={touched.email ? errors.email : ''}
                    />
                  </div>

                  <div className="text-center">
                    {error && <div className="text-sm text-error">{error}</div>}
                    <div className="mt-8">
                      <FormButton type="submit" className="btn-primary">
                        Send Reset Password Link
                      </FormButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
          <div className="text-center">
            <p className="text-info mt-4">
              Back to{' '}
              <Link to="/login" className="underline hover:text-black">
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ForgotPassword
