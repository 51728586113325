import jwt_decode, { JwtPayload } from 'jwt-decode'
import { userStateName } from './constants'
import { UserRoles } from './enum'

export const checkTokenExpiration = (userToken: string): boolean => {
  if (userToken !== '') {
    const { exp } = jwt_decode<JwtPayload>(userToken)

    if (exp) {
      const expirationTime = exp * 1000 - 60000
      if (Date.now() >= expirationTime) {
        sessionStorage.removeItem(userStateName)

        return true
      }
    }
  }

  return false
}

export const isAdmin = (roles: string[] | undefined) => {
  if (roles) {
    return roles.includes(UserRoles.admin)
  } else {
    return false
  }
}
