import { FC } from 'react'

import { Row } from '../../../services/interfaces/common'
interface IProps {
  row: Row
}

const TableRow: FC<IProps> = ({ row }) => {
  return (
    <tr className="bg-white border-b hover:bg-slate-200">
      {row.data.map((rowItem, index) => {
        return rowItem.addOnOnClick ? (
          <td
            key={index}
            className="text-sm text-blue-700 font-light px-6 py-4 whitespace-nowrap hover:cursor-pointer"
            onClick={() => rowItem.addOnOnClick && rowItem.addOnOnClick(row.id)}
          >
            {rowItem.addOnContent}
          </td>
        ) : (
          <td
            key={index}
            className="text-sm text-gray-900 font-light px-6 py-4 whitespace-pre-wrap"
          >
            {rowItem.addOnContent}
          </td>
        )
      })}
    </tr>
  )
}

export default TableRow
