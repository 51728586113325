// import { Logos } from '../../resources'

// const BasicHeader = () => {
//   return (
//     <div className="header-wrapper p-14">
//       <div className="container text-center">
//         <div className="inline-block w-full">
//           <div className="hidden md:flex flex-row md:justify-between max-h-36">
//               <img src={Logos.WaterAlertNotice} className="md:w-[20%] md:min-w-[300px]" title="Register for water alerts"></img>
//               <img src={Logos.HydroLogo} className="md:w-[20%] md:min-w-[300px]" title="Hydro Tasmania"></img>
//           </div>
//           <div className="block md:hidden">       
//             <div className="justify-center mb-2">
//               <img src={Logos.HydroLogo} className="md:w-[20%] md:min-w-[300px]" title="Hydro Tasmania"></img>
//             </div>
//             <div className="justify-center">
//               <img src={Logos.WaterAlertNotice} className="md:w-[20%] md:min-w-[300px]" title="Register for water alerts"></img>
//             </div>
//           </div>
//         </div>
//       </div>      
//     </div>
//   )
// }

// export default BasicHeader

import LogoIcon from '../UI/Icon/LogoIcon'

const BasicHeader = () => {
  return (
    <div className="header-wrapper p-5">
      <div className="container text-center md:text-left">
        <div className="inline-block">
          <div className="flex flex-row">
            <div>
              <LogoIcon />
            </div>
            <div className="flex flex-col pl-2 justify-between">
              <p className="font-normal text-3xl leading-9">Hydro Tasmania</p>
              <p className="text-4-5xl leading-10">Water Alerts</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasicHeader
