import { TouchEvent, useCallback, useState } from 'react'
import Indicator from './Indicator'

export type TutorialImages = {
  url: string[]
  text: string
}

interface IProps {
  images: TutorialImages[]
  background: string
  onSkipTutorial: () => void
}

const Slider: React.FC<IProps> = ({
  images,
  background,
  onSkipTutorial,
  ...props
}) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const [touchPosition, setTouchPosition] = useState<number | null>(null)

  const nextSlide = (slideIndex = currentSlide + 1) => {
    const updatedSlideIndex = slideIndex >= images.length ? 0 : slideIndex
    setCurrentSlide(updatedSlideIndex)
  }

  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      const touchDown = e.touches[0].clientX
      setTouchPosition(touchDown)
    },
    [touchPosition]
  )

  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      const touchDown = touchPosition

      if (touchDown === null) {
        return
      }

      const currentTouch = e.touches[0].clientX
      const diff = touchDown - currentTouch

      if (diff > 5) {
        if (currentSlide !== images.length - 1) {
          nextSlide()
        }
      }

      if (diff < -5) {
        if (currentSlide !== 0) {
          nextSlide(currentSlide - 1)
        }
      }

      setTouchPosition(null)
    },
    [touchPosition]
  )

  const tutorialImages = (images: TutorialImages) => {
    const imagesCode = images.url.map((url, index) => {
      return (
        <img
          src={url}
          alt="TutorialImage"
          className="mx-5 xt-5 mb-40"
          key={index}
        />
      )
    })

    return imagesCode
  }

  return (
    <div
      className="slider-wrapper"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      {...props}
    >
      {images?.map((image, index) => {
        return (
          <div
            className="slide flex flex-col overflow-auto"
            key={index}
            style={{
              backgroundImage: `linear-gradient(
                rgba(240, 240, 240, 0.5),
                rgba(240, 240, 240, 0.5)
              ),url(${background})`,
              marginLeft: index === 0 ? `-${currentSlide * 100}%` : undefined,
            }}
          >
            <div className="pt-8 text-center text-gray-600 select-none">
              <h1 className="text-5xl">Tutorial</h1>
              <p className="text-2xl px-12 py-8">{image.text}</p>
            </div>
            <div className="flex justify-center items-center ">
              {tutorialImages(image)}
            </div>
          </div>
        )
      })}
      <div className="slider-footer">
        {currentSlide === images.length - 1 ? (
          <div className="btn-hint cursor-pointer" onClick={onSkipTutorial}>
            {'Get Started'}
          </div>
        ) : (
          <div className="btn-hint cursor-pointer" onClick={() => nextSlide()}>
            {'Next Hint'}
          </div>
        )}

        <Indicator
          currenstSlide={currentSlide}
          totalSlides={images.length}
          nextSlide={nextSlide}
        />
        <div
          className="hover:text-white cursor-pointer"
          onClick={onSkipTutorial}
        >
          Skip Tutorial
        </div>
      </div>
    </div>
  )
}

export default Slider
