import TutorialScreenshotTwo from '../../components/UI/Images/TutorialScreenshotTwo.png'
import Icons from '../../resources'
import TutorialPageBG from '../../components/UI/Images/TutorialPageBG.png'
import { Slider } from '../../components/UI'
import { TutorialImages } from '../../components/UI/Slider/Slider'
import { useNavigate } from 'react-router-dom'
import { PageLinks } from '../../helper/enum'
import UserService from '../../services/UserService'
import { userStateName } from '../../helper/constants'

const images: TutorialImages[] = [
  {
    url: [Icons.FlowInactive, Icons.SpillInactive, Icons.WaterLevelInactive],
    text: 'These icons represent available sites',
  },
  {
    url: [TutorialScreenshotTwo],
    text: 'By selecting the icon you can set the alert conditions',
  },
  {
    url: [Icons.FlowActive, Icons.SpillActive, Icons.WaterLevelActive],
    text: 'Once active, the alert icons will look like this',
  },
]

const Tutorial = ({ ...props }) => {
  const navigate = useNavigate()

  const handleSkipTutorial = () => {
    const { authToken, authContext } = props

    if (authToken) {
      UserService.updateUserSkipTutorial(authToken, authContext?.user?.id)
        .then(() => {
          const currentUser = authContext?.user

          if (currentUser) {
            currentUser.skipTutorial = true
          }

          sessionStorage.setItem(userStateName, JSON.stringify(currentUser))
          authContext.setUser(currentUser)

          navigate(PageLinks.Alert)
        })
        .catch((error) => console.log('Error', error))
    }
  }

  return (
    <Slider
      images={images}
      background={TutorialPageBG}
      onSkipTutorial={handleSkipTutorial}
    />
  )
}

export default Tutorial
