import { useState } from 'react'
import { SubscriptionDetails } from '../../services/interfaces/map'

const useMarker = (subscriptionDetails: SubscriptionDetails[]) => {
  const [subscriptions, setSubscriptions] =
    useState<SubscriptionDetails[]>(subscriptionDetails)

  const [activeLevel, setActiveLevel] = useState<string>(
    subscriptionDetails[Math.round((subscriptionDetails.length - 1) / 2)]
      .alertlevelId
  )

  const updateState = (newNotification: string): void => {
    const clonedArray = subscriptions.map((a) => {
      return { ...a }
    })

    const updatedSubscription: SubscriptionDetails[] = clonedArray.map(
      (subs) => {
        if (subs.alertlevelId === activeLevel) {
          if (subs.subscriptionTypes === null) {
            subs.subscriptionTypes = [newNotification]
          } else {
            if (subs.subscriptionTypes.indexOf(newNotification) < 0) {
              subs.subscriptionTypes.push(newNotification)
            } else {
              const index = subs.subscriptionTypes.indexOf(newNotification)

              if (index > -1) {
                subs.subscriptionTypes.splice(index, 1)
              }
            }
          }
        }

        return subs
      }
    )

    setSubscriptions(updatedSubscription)
  }

  const hasSubscription = (id: string): boolean => {
    const subsLength = subscriptions.find((x) => x.alertlevelId === id)
      ?.subscriptionTypes?.length

    if (subsLength) {
      return subsLength > 0
    }

    return false
  }

  const isActiveSubscription = (subscriptionTypes: string): boolean => {
    const currentSubscription = subscriptions.find(
      (x) => x.alertlevelId === activeLevel
    )

    if (currentSubscription) {
      const currentNotificationType: string[] =
        currentSubscription.subscriptionTypes as string[]
      if (
        currentNotificationType.findIndex((x) => x === subscriptionTypes) > -1
      ) {
        return true
      }
    }

    return false
  }

  return {
    activeLevel,
    subscriptions,
    hasSubscription,
    isActiveSubscription,
    setActiveLevel,
    setSubscriptions,
    updateState,
  }
}

export default useMarker
