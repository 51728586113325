import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import toast from 'react-hot-toast'
import {
  createSiteUrl,
  getAlertLevelsUrl,
  getSites,
  getUsersUrl,
  sendNotificationUrl,
  updateAlertLevelUrl,
  updateSiteUrl,
  updateTemplateUrl,
  updateUserUrl,
} from '../../helper/constants'
import {
  AlertLevel,
  AlertLevelNotification,
  PageOptions,
  Users,
  NotificationTemplate,
  Histories,
  Sites,
} from '../interfaces/admin'
import { PatchDataRequest } from '../interfaces/common'
import { CreateSiteRequest, UpdateSiteRequest } from '../interfaces/site'
import { UpdateUserRequest } from '../interfaces/user'
import { store } from '../stores/store'

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

axios.interceptors.response.use(
  async (response) => {
    return response
  },
  (error: AxiosError) => {
    if (error.response) {
      const { data } = error.response
      toast.error(`Error: ${data.error}`)
    } else {
      toast.error(`Error: Unexpected error`)
    }

    return Promise.reject(error)
  }
)

const responseBody = <T>(response: AxiosResponse<T>) => response.data

const requests = {
  get: <T>(url: string, options: AxiosRequestConfig<object> | undefined) =>
    axios.get<T>(url, options).then(responseBody),
  patch: (url: string, body: object[]) =>
    axios.patch(url, body).then(responseBody),
  post: (url: string, body: object) => axios.post(url, body).then(responseBody),
  put: (url: string, body: object) => axios.put(url, body).then(responseBody),
}

const AlertLevels = {
  getAlertLevels: (options: AxiosRequestConfig<PageOptions> | undefined) =>
    requests.get<AlertLevel>(getAlertLevelsUrl, options),
  sendNotification: (notification: AlertLevelNotification) =>
    requests.post(sendNotificationUrl, notification),
  updateAlertLevel: (id: string, alertLevelChanges: PatchDataRequest[]) =>
    requests.patch(`${updateAlertLevelUrl}/${id}`, alertLevelChanges),
}

const Templates = {
  updateTemplate: (template: NotificationTemplate) =>
    requests.put(updateTemplateUrl, template),
}

const WAUsers = {
  getUsers: (options: AxiosRequestConfig<PageOptions> | undefined) =>
    requests.get<Users>(getUsersUrl, options),
  updateUser: (user: UpdateUserRequest) => requests.put(updateUserUrl, user),
}

const HistoryList = {
  getHistories: (options: AxiosRequestConfig<PageOptions> | undefined) =>
    requests.get<Histories>(sendNotificationUrl, options),
}

const Site = {
  getSites: (options: AxiosRequestConfig<PageOptions> | undefined) =>
    requests.get<Sites>(getSites, options),
  updateSite: (site: UpdateSiteRequest) => requests.put(updateSiteUrl, site),
  createSite: (site: CreateSiteRequest) => requests.post(createSiteUrl, site),
}

const agent = {
  AlertLevels,
  WAUsers,
  Templates,
  HistoryList,
  Site,
}

export default agent
