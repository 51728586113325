import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { Header } from '../../../components'
import { AdminPortal } from '../../../components/Header/HeaderList'
import { Loading, Modal, Table } from '../../../components/UI'
import {
  SiteTableColumn,
  SitePageSize,
  SiteSaveSuccessMessage,
} from '../../../helper/constants'
import { HeaderName, PageLinks, SiteStatus } from '../../../helper/enum'
import { Pages, TableColumnType } from '../../../services/interfaces/admin'
import { useStore } from '../../../services/stores/store'

const SitePage = () => {
  const { siteStore } = useStore()
  const {
    sites,
    selectedSite,
    newSite,
    siteOptions,
    isLoading,
    loadSites,
    setSelectedSite,
    setNewSite,
    setSiteOptions,
    getTableRows,
    updateSiteInfo,
    saveSite,
  } = siteStore

  const columns: TableColumnType[] = SiteTableColumn

  const pages: Pages = {
    currentPage: sites?.currentPage || 1,
    totalPage: sites?.totalPage || 1,
    pageSize: SitePageSize,
    options: siteOptions,
    setPageOptions: setSiteOptions,
  }

  const getModalBody = () => {
    const site = selectedSite || newSite
    const latitude = site.coordinates.split(',')[0] ?? ''
    const longitude = site.coordinates.split(',')[1] ?? ''

    return (
      <div>
        <div className="inline-flex mb-3 w-full">
          <label className="w-[20%]" htmlFor="mobile">
            Name
          </label>
          <input
            name="name"
            maxLength={150}
            style={{ resize: 'none' }}
            className="px-3 border-2 rounded-md bg-black opacity-80 w-[80%]"
            value={site.name}
            onChange={(event) => {
              const input = event.target.value
              const words = input.split(' ')
              const charCount = input.length

              if (words.length > 8 || charCount > 150) {
                event.preventDefault()
                toast.error('Name can have only 8 words and 150 characters')
              } else {
                updateSiteInfo({
                  ...site,
                  name: event.target.value,
                })
              }
            }}
            title="Please provide a brief description of the site (maximum 8 words or 150 characters)."
          />
        </div>
        <div className="inline-flex mb-3 w-full">
          <label className="w-[20%]" htmlFor="mobile">
            Description
          </label>
          <textarea
            name="description"
            rows={3}
            maxLength={300}
            style={{ resize: 'none' }}
            className="px-3 border-2 rounded-md bg-black opacity-80 w-[80%]"
            value={site.description}
            onChange={(event) => {
              const input = event.target.value
              const words = input.split(' ')
              const charCount = input.length

              if (words.length > 24 || charCount > 300) {
                event.preventDefault()
                toast.error(
                  'Description can have only 24 words and 300 characters'
                )
              } else {
                updateSiteInfo({
                  ...site,
                  description: event.target.value,
                })
              }
            }}
            title="Please provide a brief description of the site (maximum 24 words or 300 characters)."
          />
        </div>

        <div className="inline-flex mb-3 w-full">
          <label className="w-[20%]" htmlFor="mobile">
            Latitude
          </label>
          <input
            name="coordinates"
            maxLength={30}
            style={{ resize: 'none' }}
            className="px-3 border-2 rounded-md bg-black opacity-80 w-[80%]"
            value={latitude}
            onChange={(event) => {
              const latitude = event.target.value
              const longitude = site.coordinates.split(',')[1]
              const newCoordinates = `${latitude},${longitude}`
              updateSiteInfo({
                ...site,
                coordinates: newCoordinates,
              })
            }}
          />
        </div>
        <div className="inline-flex mb-3 w-full">
          <label className="w-[20%]" htmlFor="mobile">
            Longitude
          </label>
          <input
            name="coordinates"
            maxLength={30}
            style={{ resize: 'none' }}
            className="px-3 border-2 rounded-md bg-black opacity-80 w-[80%]"
            value={longitude}
            onChange={(event) => {
              const longitude = event.target.value
              const latitude = site.coordinates.split(',')[0]
              const newCoordinates = `${latitude},${longitude}`
              updateSiteInfo({
                ...site,
                coordinates: newCoordinates,
              })
            }}
          />
        </div>
        <div className="inline-flex mb-3 w-full">
          <label className="w-[20%]" htmlFor="status">
            Status
          </label>
          <select
            name="status"
            className="px-2 border-2 rounded-md bg-black opacity-80 w-[80%]"
            value={site.status ? SiteStatus.Active : SiteStatus.Inactive}
            onChange={(event) => {
              updateSiteInfo({
                ...site,
                status: event.target.value === SiteStatus.Active,
              })
            }}
          >
            {Object.values(SiteStatus).map((value, index) => (
              <option key={index} label={value} value={value} />
            ))}
          </select>
        </div>
      </div>
    )
  }

  const handleClose = () => {
    setSelectedSite('')
  }

  const handleSave = async () => {
    try {
      await saveSite()
      toast.success(SiteSaveSuccessMessage)
    } catch (error) {
      console.log('Error saving user changes.')
    } finally {
      setSelectedSite('')
      setNewSite()
    }
  }

  useEffect(() => {
    loadSites(siteOptions)
  }, [siteOptions])

  if (isLoading) {
    return (
      <div className="h-screen">
        <Loading />
      </div>
    )
  }

  return (
    <div>
      <Header
        page={PageLinks.Site}
        header={AdminPortal}
        currentPageName={HeaderName.Site}
      />
      <div className="flex flex-col mb-5">
        <span className="mt-20 "></span>
        <button
          className="btn ml-auto m-10 mb-0 px-6 py-2.5 text-sm bg-secondary text-white font-medium  leading-tight uppercase !rounded shadow-md hover:opacity-80 hover:shadow-lg focus:opacity-80  focus:shadow-lg focus:outline-none focus:ring-0 active:opacity-70 active:shadow-lg transition duration-150 ease-in-out "
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#ht-modal"
        >
          Add
        </button>
      </div>

      <Table columns={columns} rows={getTableRows()} pages={pages} />
      <Modal
        header={
          <h5
            className="text-xl font-medium leading-normal text-white"
            id="ht-modal-label"
          >
            {`${selectedSite?.name || ''}`}
          </h5>
        }
        body={getModalBody()}
        footer={
          <button
            type="button"
            className="inline-block px-6 py-2.5 bg-secondary text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:bg-opacity-90 hover:shadow-lg active:bg-opacity-80 active:shadow-lg transition duration-150 ease-in-out ml-1"
            data-bs-dismiss="modal"
            onClick={() => {
              handleSave()
            }}
          >
            Save
          </button>
        }
        onClose={handleClose}
      ></Modal>
    </div>
  )
}
export default observer(SitePage)
