import * as yup from 'yup'

export const registerSchema = yup.object({
    firstName: yup.string().trim().max(50, 'First name must be at most 50 characters').required('First name is required'),
    lastName: yup.string().trim().max(50, 'Last name must be at most 50 characters').required('Last name is required'),
    email: yup.string().email('Email must be in a valid format').required('Email is required'),
    mobileNumber: yup
    .string()
    .required('Mobile number is required')
    .matches(/^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/, 'Phone number is not valid'),
    isVerified: yup.boolean().oneOf([true], 'ReCAPTCHA box must be checked'),
    password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password entered is too short')
    .max(50, 'Password entered is too big')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.{8,})/,
      'Enter password matching below criterias'
    ),
})

export type RegisterFormData = yup.InferType<typeof registerSchema>
export type RegisterUserData = Omit<RegisterFormData, 'isVerified'>