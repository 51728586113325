import React, { FC } from 'react'

interface IProps {
  siteName: string
  level: string
  subscribers: number
  onClick: () => void
}
const Grid: FC<IProps> = ({ siteName, level, subscribers, onClick }) => {
  return (
    <div
      className=" h-60 w-60 p-4 bg-[#404040] flex flex-col items-center justify-evenly rounded-xl shadow-md hover:cursor-pointer hover:opacity-90 hover:translate-y-1"
      onClick={onClick}
    >
      <div className="text-secondary text-xl md:text-lg text-center font-light whitespace-nowrap overflow-hidden text-ellipsis w-full">
        {siteName}
      </div>
      <div className="text-white text-center text-lg md:text-base font-extralight">
        {level}
      </div>
      <div className="text-white text-center text-lg md:text-base font-extralight">
        {subscribers} Subscriber(s)
      </div>
    </div>
  )
}

export default Grid
