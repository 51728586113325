import { FC, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageLinks } from '../../helper/enum'
import { userStateName } from '../../helper/constants'
import FlyoutMenu from './FlyoutMenu'
import useAuth from '../../hooks/useAuth'
import LinkItems from './LinkItems'
import { headerButtonList, IPropsHeaderList } from './HeaderList'
import HeaderButton from '../UI/Button/HeaderButton'
import { isAdmin } from '../../helper/authHelper'
import { useStore } from '../../services/stores/store'
import { Logos } from '../../resources'

interface IProps {
  page: PageLinks
  currentPageName?: string
  header: IPropsHeaderList
}

const Header: FC<IProps> = ({ page, currentPageName = '', header }) => {
  const {commonStore} = useStore()
  const [showFlyoutMenu, setShowFlyoutMenu] = useState(false)
  const authContext = useAuth()
  const navigate = useNavigate()

  const IsAdmin = isAdmin(authContext.user?.roles)

  const signout = useCallback(() => {
    sessionStorage.removeItem(userStateName)
    authContext.setUser(null)
    commonStore.setUser(null)
    navigate(PageLinks.Login)
  }, [])

  return (
    <>
      <FlyoutMenu
        page={page}
        onSignout={signout}
        onClose={() => setShowFlyoutMenu(false)}
        className={showFlyoutMenu ? 'show-sidebar' : 'hide-sidebar'}
        header={header}
        admin={IsAdmin}
      />
      <header
        className={`absolute w-full z-50 flex-1 ${
          !showFlyoutMenu ? 'show-header' : 'hide-header'
        }`}
      >
        <nav className="header-links">
          <div className="hidden lg:block">
            <img src={Logos.HydroLogoReversed} className="w-[55%] h-auto" title="Hydro Tasmania"></img>
          </div>
          <div
            className="space-y-1 z-50 cursor-pointer block pl-4 lg:hidden"
            onClick={() => setShowFlyoutMenu(true)}
          >
            <span className="hamburger-stick"></span>
            <span className="hamburger-stick"></span>
            <span className="hamburger-stick"></span>
          </div>
          {currentPageName !== '' && (
            <div className="page-title lg:hidden">{currentPageName}</div>
          )}
          <div className="hidden w-full lg:flex lg:items-start lg:w-auto">
            <ul className="flex flex-row items-center text-header text-white">
              <LinkItems isFlyoutMenu={false} header={header} page={page} />
            </ul>
          </div>
          <div className="signout">
            {IsAdmin && (
              <HeaderButton
                text={
                  header.isFrontEnd
                    ? headerButtonList.AdminPortal
                    : headerButtonList.ViewWebsite
                }
                navigateTo={
                  header.isFrontEnd
                    ? PageLinks.AdminCommunicate
                    : PageLinks.Alert
                }
              />
            )}
            <div onClick={() => signout()}>Sign out</div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header
