import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { NavigateFunction } from 'react-router-dom'

import {
  alertLevelsDefaults,
  emptyAlertLevel,
  TemplatePageSize,
} from '../../helper/constants'
import { PageLinks } from '../../helper/enum'
import utilities from '../../helper/utilities'
import agent from '../api/agent'
import { AlertLevel, AlertLevelData, PageOptions } from '../interfaces/admin'
import { Row, Rows } from '../interfaces/common'
import { PatchDataRequest } from '../interfaces/common'

export default class AlertLevelStore {
  alertLevels: AlertLevel | undefined = undefined
  alertLevelsRegistry: Map<string, AlertLevelData> = new Map<
    string,
    AlertLevelData
  >()
  selectedAlertLevel: AlertLevelData | undefined = undefined
  alertLevelsOptions: PageOptions = {
    ...alertLevelsDefaults,
    PageSize: TemplatePageSize,
  }
  isLoading = true

  constructor() {
    makeAutoObservable(this)

    makePersistable(this, {
      name: 'AlertLevelStore',
      properties: [
        'alertLevels',
        'alertLevelsRegistry',
        'selectedAlertLevel',
        'alertLevelsOptions',
        'isLoading',
      ],
      storage: window.sessionStorage,
    })
  }

  loadAlertLevels = async (options: PageOptions | undefined = undefined) => {
    try {
      options = options || alertLevelsDefaults
      const result = await agent.AlertLevels.getAlertLevels({ params: options })

      this.setAlertLevels(result)

      result.data.map((alertLevelData) => {
        this.setAlertLevel(alertLevelData.id, alertLevelData)
      })
    } catch (error) {
      this.setAlertLevels(emptyAlertLevel)
    } finally {
      this.setIsLoading(false)
    }
  }

  setAlertLevels = (alertLevels: AlertLevel) => {
    this.alertLevels = alertLevels
  }

  selectAlertLevel = (id: string) => {
    this.selectedAlertLevel = this.alertLevelsRegistry.get(id)
  }

  updateSelectedAlertLevel = (alertLevel: AlertLevelData) => {
    this.selectedAlertLevel = alertLevel
  }

  saveAlertLevelChanges = async () => {
    if (this.selectedAlertLevel) {
      const alertLevelChanges: PatchDataRequest[] = [
        {
          operationType: 'Replace',
          path: 'Description',
          value: this.selectedAlertLevel.description || '',
        },
        {
          operationType: 'Replace',
          path: 'Name',
          value: this.selectedAlertLevel.name || '',
        },
      ]

      await agent.AlertLevels.updateAlertLevel(
        this.selectedAlertLevel.id,
        alertLevelChanges
      )
    }
  }

  setAlertLevelsOptions = (options: PageOptions) => {
    this.alertLevelsOptions = options
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  getTableRows: (nav: NavigateFunction) => Rows = (nav) => {
    const alertLevels: Rows = { rows: [] }

    if (this.alertLevels) {
      this.alertLevels.data.forEach((alertLevel) => {
        const row: Row = { id: alertLevel.id, data: [] }
        row.data.push(utilities.getTextAddOn(alertLevel.measurementSite))
        row.data.push(utilities.getTextAddOn(alertLevel.name))
        row.data.push(utilities.getTextAddOn(alertLevel.templateName))
        row.data.push(
          utilities.getTextAddOn(
            `${alertLevel.subscribedUsers.length} Subscriber(s)`
          )
        )
        row.data.push({
          addOnContent: 'Edit',
          addOnOnClick: (id: string) => {
            this.selectAlertLevel(id)
            nav(PageLinks.AdminEditTemplate)
          },
        })
        alertLevels.rows.push(row)
      })
    }

    return alertLevels
  }

  private setAlertLevel = (id: string, alertLevelData: AlertLevelData) => {
    this.alertLevelsRegistry.set(id, alertLevelData)
  }
}
