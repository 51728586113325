import { ButtonVariant } from '../../../helper/enum'
import { IOverridableClass } from './Input'

interface IProps
  extends IOverridableClass,
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > {
  isSubmitting?: boolean
  variant?: ButtonVariant
}

const FormButton: React.FC<IProps> = ({
  isSubmitting = false,
  className,
  variant = ButtonVariant.Primary,
  ...props
}) => {
  return (
    <button
      className={`${
        variant === ButtonVariant.Primary
          ? 'bg-black bg-opacity-50 w-44'
          : 'bg-secondary w-28'
      } hover:bg-opacity-100 text-white font-normal leading-7 text-2xl py-2 px-4 rounded-full ${className} ${
        isSubmitting && 'cursor-not-allowed'
      }`}
      disabled={isSubmitting}
      {...props}
    />
  )
}

export default FormButton
