import { BasicHeader, ForgotPassword } from './../../components'
import { ForgotPasswordData } from '../../components/ForgotPassword/schema'
import AuthService from '../../services/AuthService'
import { ResendEmailData } from '../../services/interfaces/auth'
import { EmailTypes } from '../../helper/enum'

const ForgotPasswordPage = () => {
  const handleForgotPassword = (values: ForgotPasswordData) => {
    const resendForgotData: ResendEmailData = {
      email: values.email ?? '',
      type: EmailTypes.Forgot.toString(),
    }
    return AuthService.forgotPass(resendForgotData)
  }
  return (
    <div className="relative">
      <div className="default-bg forgotPassword-bg">
        <BasicHeader />
        <ForgotPassword onSubmit={handleForgotPassword} />
      </div>
    </div>
  )
}

export default ForgotPasswordPage
