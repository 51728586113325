import { BasicHeader } from '../../components'

const MaintenancePage = () => {
  return (
    <div className="relative">
      <div className="default-bg login-bg">
        <BasicHeader />
        <div className="flex flex-col justify-center items-center w-4/5 mx-auto max-w-5xl ">
          <div className="mt-20 md:mt-40 w-full">            
            <h1 className="text-2xl sm:text-4xl mb-5 text-[#003150] font-bold"><span className="px-2">{"We'll be back shortly."}</span></h1>
            <div className="accordion accordion-flush mb-10">
              <div className="accordion-item !rounded-none bg-gray-200/75 mb-1">
                <div className="accordion-body py-4 px-5">{"The Hydro Tasmania's water alerts site is currently undergoing maintenance. Please try again later."}</div>
              </div>
            </div>            
          </div>
        </div>        
      </div>
    </div>
  )
}

export default MaintenancePage