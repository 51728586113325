import ReactPlayer from 'react-player/vimeo'
import 'tw-elements'
import Header from '../../components/Header/Header'
import { HeaderName, PageLinks } from '../../helper/enum'
import AccordionItem from '../../components/UI/Accordion/AccordionItem'
import { ReactElement } from 'react'
import { FrontEnd } from '../../components/Header/HeaderList'
import Icons from '../../resources'

const AboutPage = () => {
  const accordions: { title: string; body: string | ReactElement }[] = [
    {
      title: 'Are the readings real time?',
      body: 'It can take up to 30 minutes to get a reading from our remote sensors to you, but the alert will display what time the reading was taken. You can use the time advised in the alert and compare it to the water level at your location, so you can understand how long the water takes to get to you.',
    },
    {
      title: 'Which level is right for me?',
      body: 'Because changing water levels may impact property or uses in different ways, you have the option to subscribe to a range of water levels. You can subscribe to one or multiple depending on your requirements. We suggest subscribing to multiple to start with to determine the levels that work for you. ',
    },
    {
      title: "Why can't I subscribe to flood levels?",
      body: 'When water levels get to an emergency flood alert level – we stop our alerts and let the Bureau of Meteorology, Police and SES do what they do best and keep you updated. This service should not be relied on in case of emergency.',
    },
    {
      title: 'What information will be sent to me?',
      body: (
        <span>
          <strong>This is an example of an SMS you will receive:</strong>
          <br></br>Below Meadowbank has reached 3M at 13:45 29/10/2019.<br></br>
          <a
            className="text-blue-700"
            href="https://alerts.hydro.com.au/"
            target="_blank"
            rel="noreferrer"
          >
            https://alerts.hydro.com.au/
          </a>{' '}
          or reply “STOP” to unsubscribe
        </span>
      ),
    },
    {
      title:
        'Where can I find information about the water level of other water bodies managed by Hydro Tasmania? ',
      body: (
        <span>
          If you are interested in more information about Hydro Tasmania managed
          water bodies please visit -{' '}
          <a
            className="text-blue-700"
            href="https://www.hydro.com.au/water"
            target="_blank"
            rel="noreferrer"
          >
            https://www.hydro.com.au/water
          </a>
        </span>
      ),
    },
    {
      title:
        'Do you have a site you would like to see or a suggestion for a feature for  this site? ',
      body: (
        <span>
          If there is a water site you would like to see added, or a suggestion
          for a feature for the Water Alerts portal please contact us at:{' '}
          <a
            className="text-blue-700"
            href="https://www.hydro.com.au/contact"
            target="_blank"
            rel="noreferrer"
          >
            https://www.hydro.com.au/contact
          </a>
        </span>
      ),
    },
    {
      title:
        "Who do I contact if I believe the notification I'm receiving is incorrect or alarming excessively? ",
      body: (
        <span>
          Our system checks our database every 5 minutes to see if there is a
          new notification. At the same time it checks the quality of the data
          that it receives. If you have a question about the notifications you
          are receiving you can contact the Hydro Team at:{' '}
          <a
            className="text-blue-700"
            href="https://www.hydro.com.au/contact"
            target="_blank"
            rel="noreferrer"
          >
            https://www.hydro.com.au/contact
          </a>
        </span>
      ),
    },
    {
      title: 'What do the different pins on the Water Alerts map represent? ',
      body: (
        <span>
          <p>
            We have the following Pins on our Alerts Map. When you subscribe to
            the notifications for these pins, you will receive an automatic
            notification:{' '}
          </p>
          <ul className="list-disc pl-6 mt-4">
            <li>
              Spilling{' '}
              <img src={Icons.SpillInactive} className="inline-block w-6 h-7" />{' '}
              - when a Dam is spilling.
            </li>
            <li>
              Flow Rate{' '}
              <img src={Icons.FlowInactive} className="inline-block w-6 h-7" />{' '}
              - when a river is flowing at a particular rate. These are shown in
              cumecs per second.
            </li>
            <li>
              Water Level{' '}
              <img
                src={Icons.WaterLevelInactive}
                className="inline-block w-6 h-7"
              />{' '}
              - when a river has reached or fallen to a particular level. These
              are shown in meters.
            </li>
            <li>
              Community notification{' '}
              <img
                src={Icons.ManualInactive}
                className="inline-block w-6 h-7"
              />{' '}
              - when we need to manually notify the community with an update on
              a particular lake or river. For example, when a popular
              recreational lake is going to be lowered below normal levels for
              maintenance activities.
            </li>
          </ul>
        </span>
      ),
    },
  ]

  return (
    <div className="default-bg about-page-bg">
      <Header
        currentPageName={HeaderName.About}
        page={PageLinks.About}
        header={FrontEnd}
      />

      <div className="flex flex-col justify-center items-center w-4/5 mx-auto max-w-5xl ">
        <div className=" mt-20 md:mt-40 w-full">
          <div className="mt-10">
            <h1 className=" text-2xl sm:text-4xl mb-5 text-[#003150] font-bold">
              <span className="px-2">
                {"Welcome to Hydro Tasmania's water alerts site."}
              </span>
            </h1>
            <div
              className="accordion accordion-flush mb-10"
              id="accordionFlushExample"
            >
              <div className="accordion-item !rounded-none bg-gray-200/75 mb-1">
                <div className="accordion-body py-4 px-5">
                  {
                    "Changing water levels can affect water users of Hydro Tasmania managed waterways. We're helping by providing notifications when there are significant changes to water levels, following a dam release, spill or during low flows. As a registered user you can subscribe to notifications via SMS, text-to-speech phone call or email."
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="w-full relative pt-[56.25%] ">
            <ReactPlayer
              url="https://vimeo.com/755007307"
              width="100%"
              height="100%"
              controls={true}
              className="absolute top-0 left-0 bottom-0 right-0 w-full h-full"
            />
          </div>

          <div className="mt-10">
            <h1 className="text-2xl sm:text-4xl mb-5">FAQs</h1>
            <div
              className="accordion accordion-flush mb-10"
              id="accordionFlushExample"
            >
              {accordions.map((accordion, index) => (
                <AccordionItem
                  key={`accordion-item-${index}`}
                  accordion={accordion}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutPage
