import { BasicHeader, Register } from '../../components'
import { RegisterUserData } from '../../components/Register/schema'
import AuthService from '../../services/AuthService'

const RegisterPage = () => {
  const handleUserRegister = (values: RegisterUserData) => {
    return AuthService.registerUser(values)
  }

  return (
    <div className="relative">
      <div className="default-bg register-bg">
        <BasicHeader />
        <Register onSubmit={handleUserRegister} />
      </div>
    </div>
  )
}

export default RegisterPage
