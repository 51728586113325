import {
  AlertLevel,
  Histories,
  PageOptions,
  Users,
  TableColumnType,
  Sites,
  SiteData,
} from '../services/interfaces/admin'

import { MergeFields } from '../services/interfaces/common'

/* API urls */
export const confirmEmailUrl = '/api/Authentication/ConfirmEmail'
export const getAlertHistoryUrl = '/api/ContactHistory'
export const getSitesWithAlertLevelsUrl =
  '/api/MeasurementSites/SitesWithAlertLevels'
export const loginUserUrl = '/api/Authentication/Login'
export const registerUserUrl = '/api/Authentication/Register'
export const resendEmailUrl = '/api/Authentication/SendEmail'
export const resetPassUrl = '/api/Authentication/ResetPassword'
export const updateUserSubscriptionsUrl = '/api/Subscriptions'
export const getUsersUrl = '/api/Users'
export const updateUserUrl = '/api/Users'
export const getAlertLevelsUrl = '/api/AlertLevels'
export const sendNotificationUrl = '/api/ContactHistory'
export const updateAlertLevelUrl = '/api/AlertLevels'
export const updateTemplateUrl = '/api/Templates'
export const getSites = 'api/MeasurementSites/ManualSites'
export const updateSiteUrl = 'api/MeasurementSites/UpdateSite'
export const createSiteUrl = 'api/MeasurementSites/CreateSite'

/* Alert Messages */
export const SessionExpiredMessage =
  "Your session has expired. You'll be redirected to the login screen."

/* Toast Messages */
export const ConfirmEmailSuccessMessage =
  'Email has been successfully verified.'
export const ForgotPasswordResendSuccessMessage =
  "If you have an account with us we've sent you an email with instructions on how to reset your password."
export const GenericErrorMessage = 'Please contact service desk'
export const RegistrationResendSuccessMessage =
  'Registration email sent successfully'
export const RegistrationSuccessMessage = 'Registration completed successfully'
export const ResetPasswordSuccessMessage = 'Password reset successfully'
export const UserSubscriptionSaveSuccessMessage = 'Alerts saved successfully.'
export const NotificationSentSuccessMessage = 'Notification sent successfully.'
export const NotificationWithPendingPlaceholdersMessage =
  'Notifications cannot be sent with placeholders.\nPlease replace all pending placeholders indicated between {} or <>.'
export const TemplateSaveSuccessMessage = 'All changes saved successfully.'
export const UserSaveSuccessMessage = 'All user changes saved successfully.'
export const SiteSaveSuccessMessage = 'All site changes saved successfully.'

/* Error Messages */
export const nameWithSpecialCharactersError =
  'Name cannot have special characters or numbers'
export const nameEmptyError = 'Name cannot be empty'
export const incorrectMobileNumberError = 'Enter valid mobile number'
export const mobileNumberEmptyError = 'Mobile number cannot be empty'
export const passwordEmptyError = 'Password cannot be empty'
export const passwordMinimumRequirementsError =
  'Password should be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'

export const userStateName = 'wa-user'

/* Regular Expressions */
export const mergeFieldRegex = /{(.*?)}|<(.*?)>/g

/* Notification Type Map */
export const NotificationTypeMap: Map<string, string> = new Map<string, string>(
  [
    ['SMS', 'SMS'],
    ['Email', 'Email'],
    ['Voice', 'Call'],
  ]
)

export const NotificationTypeManualSitesMap: Map<string, string> = new Map<string, string>(
    [
        ['SMS', 'SMS'],
        ['Email', 'Email']
    ]
)

/* Admin portal  */

// Search Alert Levels Defaults
export const alertLevelsDefaults: PageOptions = {
  PageNumber: 1,
  PageSize: 100,
  OrderColumn: '',
  OrderDirection: '',
  SearchColumn: '',
  SearchValue: '',
}
//table component
export const noTemplatesMessage =
  'No Available templates, please create a new one.'
export const TemplatePageSize = 25

export const UsersPageSize = 25

export const HistoryPageSize = 25

export const SitePageSize = 25

export const TemplateTableColumn: TableColumnType[] = [
  {
    heading: 'Site',
    parameter: 'measurementSite',
    sortParameter: 'measurementSite.name',
    isSortable: true,
    searchParameter: 'measurementSite.name',
    isSearchable: true,
  },
  {
    heading: 'Name',
    parameter: 'name',
    sortParameter: 'name',
    isSortable: true,
    searchParameter: 'name',
    isSearchable: true,
  },
  {
    heading: 'Template Name',
    parameter: 'templateName',
    sortParameter: 'template.name',
    isSortable: true,
    searchParameter: 'template.name',
    isSearchable: true,
  },
  {
    heading: 'Subscribers',
    parameter: 'subscribedUsers',
    sortParameter: '',
    isSortable: false,
    searchParameter: '',
    isSearchable: false,
  },
]

export const UsersTableColumn: TableColumnType[] = [
  {
    heading: 'First Name',
    parameter: 'firstName',
    sortParameter: 'firstName',
    isSortable: true,
    searchParameter: 'firstName',
    isSearchable: true,
  },
  {
    heading: 'Last Name',
    parameter: 'lastName',
    sortParameter: 'lastName',
    isSortable: true,
    searchParameter: 'lastName',
    isSearchable: true,
  },
  {
    heading: 'Status',
    parameter: 'status',
    sortParameter: 'status',
    isSortable: false,
    searchParameter: 'status',
    isSearchable: false,
  },
  {
    heading: 'Type',
    parameter: 'type',
    sortParameter: 'type',
    isSortable: false,
    searchParameter: 'type',
    isSearchable: false,
  },
  {
    heading: 'Roles',
    parameter: 'roles',
    sortParameter: 'roles',
    isSortable: false,
    searchParameter: 'roles',
    isSearchable: false,
  },
]

export const HistoriesTableColumn: TableColumnType[] = [
  {
    heading: 'Sent By',
    parameter: 'createdByUser',
    sortParameter: 'createdByUser',
    isSortable: true,
    searchParameter: 'CreatedUser.FirstName',
    isSearchable: true,
  },
  {
    heading: 'Sent At',
    parameter: 'createdDate',
    sortParameter: 'createdDate',
    isSortable: true,
    searchParameter: 'createdDate',
    isSearchable: false,
  },
  {
    heading: 'Lake',
    parameter: 'site',
    sortParameter: 'site',
    isSortable: true,
    searchParameter: 'AlertLevel.MeasurementSite.Name',
    isSearchable: true,
  },
  {
    heading: 'Type',
    parameter: 'alertLevel',
    sortParameter: 'alertLevel',
    isSortable: true,
    searchParameter: 'AlertLevel.Name',
    isSearchable: true,
  },
  {
    heading: 'Recipients',
    parameter: 'recipent',
    sortParameter: 'recipent',
    isSortable: false,
    searchParameter: 'recipient',
    isSearchable: false,
  },
]

export const SiteTableColumn: TableColumnType[] = [
  {
    heading: 'Measurement Site',
    parameter: 'name',
    sortParameter: 'name',
    isSortable: true,
    searchParameter: 'name',
    isSearchable: true,
  },
  {
    heading: 'Long Title',
    parameter: 'description',
    sortParameter: '',
    isSortable: false,
    searchParameter: '',
    isSearchable: false,
  },
  {
    heading: 'Status',
    parameter: 'status',
    sortParameter: 'status',
    isSortable: true,
    searchParameter: '',
    isSearchable: false,
  },
]

export const emptyAlertLevel: AlertLevel = {
  totalCount: 0,
  totalPage: 0,
  currentPage: 0,
  data: [],
}

export const emptyUsers: Users = {
  totalCount: 0,
  totalPage: 0,
  currentPage: 0,
  data: [],
}

export const emptyHistories: Histories = {
  totalCount: 0,
  totalPage: 0,
  currentPage: 0,
  data: [],
}

export const emptySites: Sites = {
  totalCount: 0,
  totalPage: 0,
  currentPage: 0,
  data: [],
}

export const emptySite: SiteData = {
  id: '',
  name: '',
  coordinates: '0,0',
  type: '',
  measurementType: '',
  measurementUnit: '',
  description: '',
  isAutomated: false,
  status: false,
  alertLevels: '',
}

export const MergeFieldList: MergeFields = {
  userName: {
    name: 'UserName',
    placeholder: '{UserName}',
    description: 'Name of the current user.',
  },
  waterLevel: {
    name: 'WaterLevel',
    placeholder: '{WaterLevel}',
    description: 'Water level that triggered the alert.',
  },
  currentDateTime: {
    name: 'CurrentDateTime',
    placeholder: '{CurrentDateTime}',
    description: 'Current date and time.',
  },
  site: {
    name: 'Site',
    placeholder: '{Site}',
    description: 'Site that triggered the alert.',
  },
  toArray: () => {
    return [
      MergeFieldList.userName,
      MergeFieldList.waterLevel,
      MergeFieldList.currentDateTime,
      MergeFieldList.site,
    ]
  },
}
