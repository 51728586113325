import { IconType } from './types'

const SpinnerIcon: React.FC<IconType> = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      {...props}
    >
      <circle
        cx={50}
        cy={50}
        fill="none"
        stroke="#3699ff"
        strokeWidth={4}
        r={32}
        strokeDasharray="150.79644737231007 52.26548245743669"
        style={{
          animationPlayState: 'paused',
        }}
      />
    </svg>
  )
}

export default SpinnerIcon
