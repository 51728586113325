import { Link } from 'react-router-dom'
import { PageLinks } from '../../helper/enum'

interface IProps {
  currentPage: PageLinks
  linkText: string
  navigateToPage: PageLinks
  className: string
  onClose?: () => void
}

const LinkItem: React.FC<IProps> = ({
  currentPage,
  linkText,
  navigateToPage,
  className,
  onClose,
}) => {
  return (
    <li onClick={onClose}>
      <Link
        to={navigateToPage}
        className={`${className}${
          currentPage === navigateToPage ? ' text-secondary' : ''
        }`}
      >
        {linkText}
      </Link>
    </li>
  )
}

export default LinkItem
