import { createContext, useState } from 'react'
import { userStateName } from '../helper/constants'
import { UserContextType, UserData } from '../services/interfaces/auth'

type AuthContextProviderProps = {
  children: React.ReactNode
}

const storedState = sessionStorage.getItem(userStateName)
const initialState = {} as UserContextType

if (storedState !== null) initialState.user = JSON.parse(storedState)

export const AuthContext = createContext(initialState)

export const AuthProvider = ({ children }: AuthContextProviderProps) => {
  const [user, setUser] = useState<UserData | null>(initialState?.user)

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  )
}
