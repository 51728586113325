import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import AuthService from '../../services/AuthService'
import Loading from '../../components/UI/Loading'
import { ConfirmEmailData, UserData } from '../../services/interfaces/auth'
import { getQueryParameter } from '../../helper/urlHelper'
import { InfoIcon } from '../../components/UI'
import { PageLinks } from '../../helper/enum'
import { ServerErrors } from '../../services/interfaces/common'
import { useNavigate } from 'react-router-dom'
import {
  ConfirmEmailSuccessMessage,
  GenericErrorMessage,
  userStateName,
} from '../../helper/constants'
import useAuth from '../../hooks/useAuth'

const ConfirmEmail = () => {
  const [isLoading, setisLoading] = useState(true)
  const [errMessage, setErrMessage] = useState('')
  const userContext = useAuth()
  const navigate = useNavigate()

  const email = getQueryParameter('email')
  let token = getQueryParameter('token')
  token = token?.replaceAll(' ', '+')

  useEffect(() => {
    const userData: ConfirmEmailData = {
      email: email ?? '',
      token: token ?? '',
    }
    AuthService.confirmEmail(userData)
      .then((data: UserData) => {
        sessionStorage.setItem(userStateName, JSON.stringify(data))
        userContext?.setUser(data)
        navigate(PageLinks.TermsAndConditions)
        toast.success(`'${data?.email}' ${ConfirmEmailSuccessMessage}`)
      })
      .catch((error: ServerErrors) => {
        let serverError = ''

        if (Array.isArray(error.error)) {
          serverError = error.error[0].description
        } else {
          serverError = error.error
        }

        toast.error(serverError ?? GenericErrorMessage)
        setErrMessage(serverError ?? GenericErrorMessage)

        setisLoading(false)
      })
  }, [])

  if (isLoading) {
    return (
      <div className="h-screen">
        <Loading />
      </div>
    )
  }

  return (
    <>
      {errMessage && (
        <div className="wa-flex h-screen">
          <div className="flex flex-row">
            <InfoIcon height="22" width="22" colour="red" />
          </div>
          <div className="flex flex-col ml-4">
            <div>{errMessage}</div>
          </div>
        </div>
      )}
    </>
  )
}

export default ConfirmEmail
