import { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMagnifyingGlass,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons'

import { Pages, TableColumnType } from '../../../services/interfaces/admin'

interface IProps {
  pages: Pages
  columns: TableColumnType[]
}
const Search: FC<IProps> = ({ pages, columns }) => {
  const [enteredText, setEnteredText] = useState<string>('')
  const [column, setColumn] = useState<TableColumnType>(columns[0])

  const handleSearchChange = (columnParameter: string, text: string) => {
    pages.setPageOptions({
      ...pages.options,
      PageNumber: 1,
      PageSize: pages.pageSize,
      SearchColumn: columnParameter,
      SearchValue: text,
    })
  }

  const dropdown = columns
    .filter((column) => column.isSearchable === true)
    .map((filteredColumn, key) => {
      return (
        <li key={key}>
          <a
            className="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-white text-gray-700 hover:bg-gray-100"
            onClick={() => {
              setColumn(filteredColumn)
            }}
          >
            {filteredColumn.heading}
          </a>
        </li>
      )
    })

  return (
    <div className="flex justify-start">
      {/* dropdown */}
      <div className="flex justify-center">
        <div>
          <div className="dropdown relative">
            <button
              className="dropdown-toggle px-4 py-[10px] bg-secondary text-white font-medium text-xs leading-tight uppercase rounded-l shadow-md hover:opacity-80 hover:shadow-lg focus:opacity-80 focus:shadow-lg focus:outline-none focus:ring-0 active:opacity-70 active:shadow-lg active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {column?.heading}
              <FontAwesomeIcon icon={faSortDown} className="mb-1.5 ml-1" />
            </button>
            <ul
              className="
            dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none
          "
              aria-labelledby="dropdownMenuButton1"
            >
              {dropdown}
            </ul>
          </div>
        </div>
      </div>
      {/* search bar */}
      <div className="flex flex-col justify-center self-center mb-3">
        <div className=" xl:w-96">
          <div className="input-group relative flex flex-wrap items-stretch w-full mb-2">
            <input
              type="text"
              id="search"
              value={enteredText}
              onChange={(e) => {
                setEnteredText(e.target.value)
              }}
              className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-r transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-secondary focus:outline-none focus:shadow-none"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="button-addon2"
            />
            <button
              className="btn px-6 py-2.5 bg-secondary text-white font-medium text-md leading-tight uppercase !rounded-r shadow-md hover:opacity-80 hover:shadow-lg focus:opacity-80  focus:shadow-lg focus:outline-none focus:ring-0 active:opacity-70 active:shadow-lg transition duration-150 ease-in-out flex items-center"
              type="button"
              id="button-addon2"
              onClick={() => {
                if (column && enteredText) {
                  handleSearchChange(column.searchParameter, enteredText)
                }
              }}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
            <span className="w-5"></span>
            <button
              className="btn px-6 py-2.5 text-sm bg-secondary text-white font-medium  leading-tight uppercase !rounded shadow-md hover:opacity-80 hover:shadow-lg focus:opacity-80  focus:shadow-lg focus:outline-none focus:ring-0 active:opacity-70 active:shadow-lg transition duration-150 ease-in-out flex items-center"
              type="button"
              onClick={() => {
                handleSearchChange('', '')
                setEnteredText('')
              }}
            >
              clear
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Search
