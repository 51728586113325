import { forwardRef } from 'react'

interface IHasValidation {
  error?: string
}

export interface IOverridableClass {
  className?: string
  showErrorText?: boolean
}

interface IProps
  extends IHasValidation,
    IOverridableClass,
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > {}

const getInputValidationClasses = (isInvalid?: boolean) =>
  isInvalid ? 'border border-error outline-none invalid' : ''

// eslint-disable-next-line react/display-name
const FormInput = forwardRef<HTMLInputElement, IProps>(
  ({ className = '', error, showErrorText = true, ...inputProps }, ref) => {
    const isInvalid = Boolean(error)
    const validationClasses = getInputValidationClasses(isInvalid)
    return (
      <>
        <input
          ref={ref}
          className={`block w-full text-sm font-normal focus:outline-none focus:border-grey-500 focus:ring-1 focus:ring-grey-500 bg-black bg-opacity-50 ${validationClasses} ${
            isInvalid ? 'placeholder:text-white' : ''
          } ${className} disabled:bg-white`}
          {...inputProps}
        />
        {isInvalid && showErrorText ? (
          <div className="text-sm mt-1 text-error mr-10">{error}</div>
        ) : null}
      </>
    )
  }
)

export type { IHasValidation }
export { getInputValidationClasses }
export default FormInput
