import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import agent from "../api/agent";
import { AlertLevelNotification } from "../interfaces/admin";

export default class NotificationStore {
    alertLevelId = ""
    content = ""
    subject = ""

    mergeFieldValues: Map<string, string> = new Map<string, string>()

    isEditorDisabled = true
    isSending = false

    constructor() {
        makeAutoObservable(this)

        makePersistable(
            this, 
            { name: "NotificationStore", properties: ["alertLevelId", "content", "subject"], storage: window.sessionStorage }
        );
    }

    processMergeFields = (content: string) => {
        this.mergeFieldValues.forEach((value, key) => {
            content = content.replaceAll(key, value)
        })

        this.setContent(content)
    }

    setMergeField = (key: string, value: string) => {
        this.mergeFieldValues.set(key, value)
    }

    setAlertLevelId = (id: string) => {
        this.alertLevelId = id
    }

    setContent = (content: string) => {
        this.content = content
    }

    setSubject = (subject: string) => {
        this.subject = subject
    }

    setIsEditorDisabled = (isDisabled: boolean) => {
        this.isEditorDisabled = isDisabled
    }

    setIsSending = (isSending: boolean) => {
        this.isSending = isSending
    }

    sendNotification = async (userId: string) => {
        const notification: AlertLevelNotification = {
            AlertLevelId: this.alertLevelId,
            Content: this.content,
            Subject: this.subject,
            CreatedUserId: userId
        }

        this.setIsSending(true)

        try {            
            await agent.AlertLevels.sendNotification(notification)
        }
        finally {
            this.setIsSending(false)
        }
    }
}