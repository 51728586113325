import { AxiosRequestHeaders } from 'axios'
import { useState } from 'react'
import { BasicHeader } from '../../components'
import { FormButton } from '../../components/UI'
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { getAuthorisationToken } from '../../helper/mapHelper'
import UserService from '../../services/UserService'
import { userStateName } from '../../helper/constants'
import { PageLinks } from '../../helper/enum'

const TermsAndConditionsPage = () => {
  const userContext = useAuth()
  const navigate = useNavigate()
  const [isValid, setValid] = useState<boolean>(true)
  const [isChecked, setChecked] = useState<boolean>(false)
  const authToken: AxiosRequestHeaders | null = getAuthorisationToken()

  const handleCheck = () => {
    setChecked(!isChecked)
    if (!isChecked) {
      setValid(true)
    }
  }

  const continueToMain = async () => {
    if (isChecked) {
      const userId = userContext?.user?.id.toString()

      if (authToken && userId) {
        UserService.updateUserSkipTermsAndConditions(authToken, userId).then(
          () => {
            const user = userContext?.user

            if (user) {
              user.skipTermsAndConditions = true
              sessionStorage.setItem(userStateName, JSON.stringify(user))
              userContext.setUser(user)
            }
            navigate(PageLinks.Alert)
          }
        )
      }
    } else {
      setValid(false)
    }
  }

  return (
    <div className="relative">
      <div className="default-bg termsConditionsBG-bg">
        <BasicHeader />
        <div className="container h-4/5 p-10">
          <div className="mt-16 mb-5 text-3xl">Terms And Conditions</div>
          <div className="py-5 text-base text-left">
            <p>
              The Hydro-Electric Corporation, trading as Hydro Tasmania (Hydro
              Tasmania), provides a water alerting Notification service in
              relation to water levels and releases, and other information at
              various sites within Tasmania (&quot;Notification Service&quot;).
              These conditions govern your use of the Notification Service:{' '}
            </p>
            <p className="mt-4">
              By clicking &quot;accept&quot; to subscribe to the Notification
              Service via this website, you request Hydro Tasmania to provide,
              and Hydro Tasmania may agree to provide, the Notification Service
              at those sites subscribed to by you from time to time. Hydro
              Tasmania may accept your request, or alternatively not register
              you for the Notification Service, in its absolute discretion. You
              acknowledge and accept that the Notification Service is: intended
              to issue a notification to you when Hydro Tasmania measuring
              points register water levels or water releases that are at a level
              identified as potentially impacting downstream users or
              infrastructure; intended to deliver the information to you via the
              email address, mobile telephone or landline telephone contact
              details that you have registered, or as you have amended from time
              to time; not intended to compliment or provide an additional
              source of information to those which may be provided by emergency
              management authorities such as the State Emergency Service (SES),
              Tasmania Police, the Bureau of Meteorology or other local
              government authorities; and not intended to replace the warnings
              and advice of any of the State Emergency Service (SES), Tasmania
              Police, the Bureau of Meteorology or other local government
              authorities or other government authorities with responsibility
              for the provision of such information, or any other Tasmanian
              emergency service. While Hydro Tasmania takes all reasonable care
              in providing the information, Hydro Tasmania accepts no
              responsibility for any decision not to register you for any site
              covered by the Notification Service, any failure to send a
              notification to you during or before an event, any failure in
              delivery of a notification to you, for any information contained
              in that notification which is inaccurate, out of date, incomplete
              or incorrect or for any action (or inaction) you take based upon
              the information. Any information provided by the Notification
              Service is provided on an &quot;as is&quot; basis, and Hydro
              Tasmania makes no representations or warranties about the quality,
              accuracy or reliability of that information. To the full extent
              permitted by law, in no event shall Hydro Tasmania be liable to
              you for any injury, claim, loss, damage, liability, cost or
              expense of any kind, including in connection with any damage
              suffered by third parties, whether caused by negligence or
              otherwise, that may be incurred or sustained by you or your
              employees, directors, officers, sub-contractors or agents in
              connection with the Notification Service or any information sent
              under it. Hydro Tasmania retains all rights in the information
              contained in any information provided by the Notification Service.
              Nothing in these conditions confers any intellectual property or
              other rights on you or any other individual in relation to that
              information. You acknowledge and accept that: you are responsible
              for registering correct and complete contact details, and for
              updating your details should they change; you are responsible for
              identifying which site/s covered by the Notification Service may
              be of relevance to you and for subscribing to the same; and
              delivery of the information over email, mobile or landline
              telephone systems is not instantaneous, and may be affected by
              outages or interruptions unknown to and beyond the control of
              Hydro Tasmania.
            </p>
            <p className="mt-4">
              Hydro Tasmania is collecting your personal information at your
              request in order to provide you with the Notification Service.
              Your personal information will be managed in accordance with Hydro
              Tasmania&apos;s Privacy Policy
              https://www.hydro.com.au/privacy-policy. If you would like to
              access or update any of your personal information held by Hydro
              Tasmania, you should either log into to the online portal or
              contact: contactus@hydro.com.au. Hydro Tasmania may discontinue
              the Notification Service, either in its entirety or in relation to
              your receipt of it, at any time without notice to you. Hydro
              Tasmania may add additional notifications services in the future
              and it is your responsibility to determine if they are relevant to
              you and to modify your subscription to include them if desired.
              These conditions are governed by the laws in force in Tasmania,
              Australia.
            </p>
          </div>
          <div className="py-14 text-center">
            <div className="mb-8">
              <input
                type="checkbox"
                id="accept-terms"
                className={`scale-150 ${
                  isValid ? '' : 'outline-red-600 outline outline-2'
                }`}
                checked={isChecked}
                onChange={handleCheck}
              />
              <span className="ml-4">I accept the terms and conditions</span>
            </div>
            <FormButton
              type="submit"
              className="btn-primary"
              onClick={continueToMain}
            >
              Continue
            </FormButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditionsPage
