import { AxiosRequestHeaders, AxiosResponse } from "axios"

import axiosClient from "./apiClient"
import { getAlertHistoryUrl, updateUserUrl } from "../helper/constants"
import { UpdateUserDataRequest, UserData } from "./interfaces/auth"
import { UserAlertHistory } from "./interfaces/user"

const fetchUser = (authToken: AxiosRequestHeaders, userId: string): Promise<AxiosResponse<UserData>> => {
    return axiosClient.get(`${updateUserUrl}/${userId}`, {
        headers: authToken
    })
        .then((response) => {
            return response
        })
        .catch(({ response }) => {
            throw response.data
        })
}

const updateUser = (authToken: AxiosRequestHeaders, userId: string, path: string, value: boolean | string): Promise<AxiosResponse<void>> => {

    const data: UpdateUserDataRequest[] = []
    if (path.toLowerCase() == 'firstname') {
        const [firstName, lastName] = value.toString().split(" ")

        data.push({
            value: firstName,
            operationType: "Replace",
            path
        })

        data.push({
            value: lastName == undefined ? '' : lastName,
            operationType: "Replace",
            path: "lastName"
        })
    }
    else {
        data.push({
            value: value,
            operationType: "Replace",
            path
        })
    }

    return axiosClient.patch(`${updateUserUrl}/${userId}`, data, {
        headers: authToken
    })
        .then((response) => {
            return response
        })
        .catch(({ response }) => {
            throw response.data
        })
}

const updateUserSkipTutorial = (authToken: AxiosRequestHeaders, userId: string): Promise<AxiosResponse<void>> => {
    return updateUser(authToken, userId, 'skipTutorial', true)
}

const updateUserSkipVideoTutorial = (authToken: AxiosRequestHeaders, userId: string): Promise<AxiosResponse<void>> => {
    return updateUser(authToken, userId, 'skipVideo', true)
}

const updateUserSkipTermsAndConditions = (authToken: AxiosRequestHeaders, userId: string): Promise<AxiosResponse<void>> => {
    return updateUser(authToken, userId, 'skipTermsAndConditions', true)
}

const updateUserName = (authToken: AxiosRequestHeaders, userId: string, firstName: string): Promise<AxiosResponse<void>> => {
    return updateUser(authToken, userId, 'firstName', firstName)
}

const updateUserMobileNumber = (authToken: AxiosRequestHeaders, userId: string, mobileNumber: string): Promise<AxiosResponse<void>> => {
    return updateUser(authToken, userId, 'phoneNumber', mobileNumber)
}

const updateUserPassword = (authToken: AxiosRequestHeaders, userId: string, password: string): Promise<AxiosResponse<void>> => {
    return updateUser(authToken, userId, 'password', password)
}

const getAlertHistory = (authToken: AxiosRequestHeaders, userId: string): Promise<AxiosResponse<UserAlertHistory[]>> => {
    return axiosClient.get(`${getAlertHistoryUrl}/${userId}`, {
        headers: authToken
    })
        .then((response) => {
            return response
        })
        .catch(({ response }) => {
            throw response.data
        })
}

const UserService = {
    fetchUser,
    getAlertHistory,
    updateUserMobileNumber,
    updateUserName,
    updateUserPassword,
    updateUserSkipTutorial,
    updateUserSkipVideoTutorial,
    updateUserSkipTermsAndConditions
}

export default UserService