import { useCallback, useMemo, useRef, useState } from 'react'
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api'

import {
  LevelDetails,
  MapCoordinates,
  SitesWithAlertLevel,
  SubscriptionDetails,
} from '../../services/interfaces/map'
import { getMapCoordinates } from '../../helper/mapHelper'
import MarkerInfo from './MarkerInfo'
import Icons from '../../resources'
import { MeasurementType } from '../../helper/enum'
import useUserSubscription from '../../hooks/useUserSubscription'

type LatLngLiteral = google.maps.LatLngLiteral
type MapOptions = google.maps.MapOptions

interface IProps {
  sites: SitesWithAlertLevel[]
  onSave: (userSubscriptions: SubscriptionDetails[]) => void
}

const Maps: React.FC<IProps> = ({ sites, onSave }) => {
  const mapRef = useRef<GoogleMap>()
  const [selected, setSelected] = useState<MapCoordinates | null>(null)
  const userSubscriptionContext = useUserSubscription()
  const [subscriptionsSnapshot, setSubscriptionsSnapshot] = useState(
    userSubscriptionContext.userSubscription
      ? JSON.parse(
          JSON.stringify(userSubscriptionContext.userSubscription.subscriptions)
        )
      : []
  )

  const onLoad = useCallback((map) => (mapRef.current = map), [])

  const center = useMemo<LatLngLiteral>(
    () => ({ lat: -42.880554, lng: 147.324997 }),
    []
  )
  const options = useMemo<MapOptions>(
    () => ({
      clickableIcons: false,
      mapTypeControl: true, // Enable map type control
      mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'satellite'], // Show all map types in the control
        position: google.maps.ControlPosition.BOTTOM_LEFT,
      },
      fullscreenControl: false,
      streetViewControl: false,
    }),
    []
  )

  const handleClose = () => {
    if (userSubscriptionContext.userSubscription) {
      userSubscriptionContext.userSubscription.subscriptions = JSON.parse(
        JSON.stringify(subscriptionsSnapshot)
      )
    }

    setSelected(null)
  }

  const getIcon = (
    measurementType: string,
    alertLevels: LevelDetails[],
    isAutomated: boolean
  ): string => {
    let icon = ''
    const isActive =
      userSubscriptionContext.userSubscription?.subscriptions.some(
        (userSubscription) =>
          alertLevels?.some(
            (alertLevel) => userSubscription.alertlevelId === alertLevel.id
          )
      )

    switch (measurementType) {
      case MeasurementType.WaterLevel:
        icon = isActive
          ? isAutomated
            ? Icons.WaterLevelActive
            : Icons.ManualActive
          : isAutomated
          ? Icons.WaterLevelInactive
          : Icons.ManualInactive
        break
      case MeasurementType.Flow:
        icon = isActive
          ? isAutomated
            ? Icons.FlowActive
            : Icons.ManualActive
          : isAutomated
          ? Icons.FlowInactive
          : Icons.ManualInactive
        break
      case MeasurementType.Spilling:
        icon = isActive
          ? isAutomated
            ? Icons.SpillActive
            : Icons.ManualActive
          : isAutomated
          ? Icons.SpillInactive
          : Icons.ManualInactive
        break
      case MeasurementType.Riparian:
      default:
        icon = isActive ? Icons.PlannedActive : Icons.PlannedInactive
    }

    return icon
  }

  const containerStyle = useMemo(
    () => ({
      width: '100vw',
      height: '100vh',
    }),
    []
  )

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={8}
      onLoad={onLoad}
      options={options}
    >
      {sites?.map((site: SitesWithAlertLevel) => {
        const mapCoordinates = getMapCoordinates(site.coordinates)
        const icon = getIcon(
          site.measurementType,
          site.alertLevels,
          site.isAutomated
        )

        return (
          <Marker
            key={site.id}
            position={getMapCoordinates(site.coordinates)}
            icon={icon}
            onClick={() => {
              setSelected(mapCoordinates)
            }}
          >
            {selected?.lat == mapCoordinates.lat &&
              selected?.lng == mapCoordinates.lng && (
                <InfoWindow
                  position={selected}
                  onCloseClick={handleClose}
                  options={{ maxWidth: 1000 }}
                >
                  <MarkerInfo
                    data={site}
                    setSnapshot={setSubscriptionsSnapshot}
                    onSave={onSave}
                    onClose={handleClose}
                  />
                </InfoWindow>
              )}
          </Marker>
        )
      })}
    </GoogleMap>
  )
}

export default Maps
