import { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'

import { Pages, TableColumnType } from '../../../services/interfaces/admin'
import { TableSortOrder } from '../../../helper/enum'

interface IProps {
  item: TableColumnType
  pages: Pages
}
const TableHeadItem: FC<IProps> = ({ item, pages }) => {
  const options = pages.options
  const reset = () => {
    pages.setPageOptions({ ...options, OrderColumn: '', OrderDirection: '' })
  }

  const setAscending = () => {
    pages.setPageOptions({
      ...options,
      OrderColumn: item.sortParameter,
      OrderDirection: TableSortOrder.Ascending,
    })
  }

  const setDescending = () => {
    pages.setPageOptions({
      ...options,
      OrderColumn: item.sortParameter,
      OrderDirection: TableSortOrder.Descending,
    })
  }

  const isAscending =
    options.OrderColumn === item.sortParameter &&
    options.OrderDirection === TableSortOrder.Ascending

  const isDescending =
    options.OrderColumn === item.sortParameter &&
    options.OrderDirection === TableSortOrder.Descending

  return (
    <th
      scope="col"
      className="text-md font-bold text-gray-900 px-6 py-4 text-left  "
    >
      <div className="flex self-center items-center ">
        <div
          className={`mr-2 ${item.isSortable && 'cursor-pointer'}`}
          onClick={() => {
            item.isSortable && isAscending
              ? setDescending()
              : isDescending
              ? reset()
              : setAscending()
          }}
        >
          {item.heading}
        </div>

        <div
          className={`flex flex-col text-md ${
            item.isSortable ? 'visible' : 'invisible'
          } `}
        >
          <FontAwesomeIcon
            icon={faSortUp}
            className={`cursor-pointer ${
              isAscending ? 'text-black' : 'text-gray-300'
            }`}
            onClick={() => {
              isAscending ? reset() : setAscending()
            }}
          />
          <FontAwesomeIcon
            icon={faSortDown}
            className={`cursor-pointer ${
              isDescending ? 'text-black' : 'text-gray-300'
            }`}
            onClick={() => {
              isDescending ? reset() : setDescending()
            }}
          />
        </div>
      </div>
    </th>
  )
}

export default TableHeadItem
