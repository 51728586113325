import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import Header from '../../../components/Header/Header'
import { AdminPortal } from '../../../components/Header/HeaderList'
import { FormButton, Loading, Table } from '../../../components/UI'
import {
  noTemplatesMessage,
  TemplatePageSize,
  TemplateTableColumn,
} from '../../../helper/constants'
import { HeaderName, PageLinks } from '../../../helper/enum'
import { Pages, TableColumnType } from '../../../services/interfaces/admin'
import { useStore } from '../../../services/stores/store'

const AdminTemplatesPage = () => {
  const columns: TableColumnType[] = TemplateTableColumn
  const navigate = useNavigate()
  const { alertLevelStore } = useStore()
  const {
    alertLevels,
    isLoading,
    alertLevelsOptions,
    loadAlertLevels,
    setAlertLevelsOptions,
    getTableRows,
  } = alertLevelStore

  const pages: Pages = {
    currentPage: alertLevels?.currentPage || 1,
    totalPage: alertLevels?.totalPage || 1,
    pageSize: TemplatePageSize,
    options: alertLevelsOptions,
    setPageOptions: setAlertLevelsOptions,
  }

  useEffect(() => {
    loadAlertLevels(alertLevelsOptions)
  }, [alertLevelsOptions])

  if (isLoading)
    return (
      <div className="h-screen">
        <Loading />
      </div>
    )

  return (
    <div>
      <Header
        page={PageLinks.AdminTemplates}
        header={AdminPortal}
        currentPageName={HeaderName.AdminTemplates}
      />
      <div className="flex flex-col mb-5">
        <span className="mt-20"></span>
        {alertLevels ? (
          <Table
            columns={columns}
            rows={getTableRows(navigate)}
            pages={pages}
          />
        ) : (
          <div className="ml-5 mt-5 text-md">{noTemplatesMessage}</div>
        )}
        <div className=" self-end mr-10 mt-5">
          <FormButton
            className="mt-2 float-right !w-24 !text-lg hidden"
            onClick={() => {
              console.log('hello')
            }}
            type="button"
          >
            New
          </FormButton>
        </div>
      </div>
    </div>
  )
}

export default observer(AdminTemplatesPage)
