import { makeAutoObservable } from "mobx";
import agent from '../api/agent'
import { NotificationTemplate } from '../interfaces/admin'

export default class TemplateStore {
    constructor() {
        makeAutoObservable(this)
    }

    saveTemplateChanges = async (template: NotificationTemplate) => {
        if(template){
            try{    
                await agent.Templates.updateTemplate(template)
            }
            catch(error){
                console.error(error)
                throw(error)
            }
        }
    }
}