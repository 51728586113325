import { AxiosRequestHeaders, AxiosResponse } from "axios"

import axiosClient from "./apiClient"
import { getSitesWithAlertLevelsUrl, updateUserSubscriptionsUrl } from './../helper/constants'
import { SitesWithAlertLevel, SubscriptionDetails, UserSubscription } from "./interfaces/map"

const getSitesWithAlertLevels = (authToken: AxiosRequestHeaders): Promise<AxiosResponse<SitesWithAlertLevel[]>> => {
    return axiosClient.get(getSitesWithAlertLevelsUrl, {
        headers: authToken
    })
}

const getUserAlertSubscriptions = (authToken: AxiosRequestHeaders, userId: string): Promise<AxiosResponse<SubscriptionDetails[]>> => {
    return axiosClient.get(`${updateUserSubscriptionsUrl}/${userId}`, {
        headers: authToken
    })
}

const updateUserAlertSubscriptions = (authToken: AxiosRequestHeaders, data: UserSubscription): Promise<AxiosResponse<void>> => {
    return axiosClient.post(updateUserSubscriptionsUrl, data, {
        headers: authToken
    })
}

const MapService = {
    getSitesWithAlertLevels,
    getUserAlertSubscriptions,
    updateUserAlertSubscriptions
}

export default MapService