import { BasicHeader, Login } from '../../components'
import { LoginUserData } from '../../components/Login/schema'
import AuthService from '../../services/AuthService'

const LoginPage = () => {
  const handleUserLogin = (values: LoginUserData) => {
    return AuthService.loginUser(values)
  }

  return (
    <div className="relative">
      <div className="default-bg login-bg">
        <BasicHeader />
        <Login onLoginSubmit={handleUserLogin} />
      </div>
    </div>
  )
}

export default LoginPage
