import { getQueryParameter } from '../../helper/urlHelper'

import { BasicHeader } from '../../components'
import { FormButton } from '../UI'

interface IProps {
  onResendClick: () => void
}

const RegistrationSuccess: React.FC<IProps> = ({ onResendClick }) => {
  const email = getQueryParameter('email')

  return (
    <div className="container">
      <BasicHeader />
      <div className="container">
        <div className="absolute p-5 w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-3-5xl text-info text-center">
          <div className="mb-8">Thanks for registering</div>
          <div className="pt-5 break-words">
            We’ve sent you an email to {email ?? 'the registered email'}, please
            check it for instructions on how to activate your account{' '}
          </div>
          <div className="mt-16">
            <FormButton
              type="submit"
              className="btn-primary"
              onClick={onResendClick}
            >
              Resend
            </FormButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegistrationSuccess
