import { FormButton, FormInput } from '../../components/UI'
import { Header } from '../../components'
import { HeaderName, PageLinks, UserDataChange } from '../../helper/enum'
import useProfile from './useProfile'
import { FrontEnd } from '../../components/Header/HeaderList'

const ManageProfile = () => {
  const {
    error,
    isActive,
    mobileNumber,
    name,
    password,
    user,
    handleCancel,
    handleMobileNumberChange,
    handleNameChange,
    handlePasswordChange,
    setIsActive,
    setMobileNumber,
    setName,
    setPassword,
  } = useProfile()

  return (
    <div className="default-bg bg-image">
      <Header
        currentPageName={HeaderName.Profile}
        page={PageLinks.Profile}
        header={FrontEnd}
      />
      <div className="font-poppins md:max-w-sm">
        <div className=" text-profile pt-24 px-16 md:pt-40">
          <div>
            <label>Name</label>
            <div>
              {isActive === UserDataChange.Name ? (
                <div className="wa-flex flex-col mt-3">
                  <FormInput
                    className="form-input"
                    id="name"
                    type="text"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={error}
                    showErrorText={false}
                  />
                  {error !== '' && (
                    <div className="text-sm mt-1 text-error">{error}</div>
                  )}
                  <div className="mt-4">
                    <FormButton
                      onClick={handleNameChange}
                      className="mr-4 w-28 text-base"
                    >
                      Save
                    </FormButton>
                    <FormButton
                      className="w-28 text-base"
                      onClick={() => {
                        setName(user?.firstName ?? '')
                        handleCancel()
                      }}
                    >
                      Cancel
                    </FormButton>
                  </div>
                </div>
              ) : (
                <>
                  <span className="text-black">{name}</span>
                  <div
                    className={`text-profile-links ml-8 inline ${
                      isActive !== null
                        ? 'pointer-events-none'
                        : 'cursor-pointer'
                    }`}
                    onClick={() => setIsActive(UserDataChange.Name)}
                  >
                    Change
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="mt-8">
            <label>Email</label>
            <div>
              <span className="text-black underline">{user?.email}</span>
            </div>
          </div>
          <div className="mt-8">
            <label>Mobile number</label>
            <div>
              {isActive === UserDataChange.MobileNumber ? (
                <div className="wa-flex flex-col mt-3">
                  <FormInput
                    className="form-input"
                    id="mobileNumber"
                    type="text"
                    placeholder="Enter mobile number"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    error={error}
                    showErrorText={false}
                  />
                  {error !== '' && (
                    <div className="text-sm mt-1 text-error">{error}</div>
                  )}
                  <div className="mt-4">
                    <FormButton
                      onClick={handleMobileNumberChange}
                      className="mr-4 w-28 text-base"
                    >
                      Save
                    </FormButton>
                    <FormButton
                      className="w-28 text-base"
                      onClick={() => {
                        setMobileNumber(user?.mobileNumber ?? '')
                        handleCancel()
                      }}
                    >
                      Cancel
                    </FormButton>
                  </div>
                </div>
              ) : (
                <>
                  <span className="text-black">{mobileNumber}</span>
                  <div
                    className={`text-profile-links ml-8 inline ${
                      isActive !== null
                        ? 'pointer-events-none'
                        : 'cursor-pointer'
                    }`}
                    onClick={() => setIsActive(UserDataChange.MobileNumber)}
                  >
                    Change
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="mt-8">
            <label>Password</label>
            <div>
              {isActive === UserDataChange.Password ? (
                <div className="wa-flex flex-col mt-3">
                  <FormInput
                    className="form-input"
                    id="password"
                    type="password"
                    placeholder="Enter new password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={error}
                    showErrorText={false}
                  />
                  {error !== '' && (
                    <div className="text-sm mt-1 text-error">{error}</div>
                  )}
                  <div className="mt-4">
                    <FormButton
                      onClick={handlePasswordChange}
                      className="mr-4 w-28 text-base"
                    >
                      Save
                    </FormButton>
                    <FormButton
                      className="w-28 text-base"
                      onClick={() => {
                        setPassword('')
                        handleCancel()
                      }}
                    >
                      Cancel
                    </FormButton>
                  </div>
                </div>
              ) : (
                <>
                  <span className="text-black">**********</span>
                  <div
                    className={`text-profile-links ml-8 inline ${
                      isActive !== null
                        ? 'pointer-events-none'
                        : 'cursor-pointer'
                    }`}
                    onClick={() => setIsActive(UserDataChange.Password)}
                  >
                    Change
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageProfile
