import { AxiosRequestHeaders } from 'axios'
import { useNavigate } from 'react-router-dom'
import { FormButton } from '../../components/UI'
import { userStateName } from '../../helper/constants'
import { PageLinks } from '../../helper/enum'
import { getAuthorisationToken } from '../../helper/mapHelper'
import useAuth from '../../hooks/useAuth'
import UserService from '../../services/UserService'
import ReactPlayer from 'react-player/vimeo'

const IntroVideo = () => {
  const userContext = useAuth()
  const navigate = useNavigate()
  const authToken: AxiosRequestHeaders | null = getAuthorisationToken()

  const handleSkipVideo = () => {
    const userId = userContext?.user?.id.toString()

    if (authToken && userId) {
      UserService.updateUserSkipVideoTutorial(authToken, userId).then(() => {
        const user = userContext?.user

        if (user) {
          user.skipVideo = true
          sessionStorage.setItem(userStateName, JSON.stringify(user))
          userContext.setUser(user)
        }
        navigate(PageLinks.Alert)
      })
    }
  }

  return (
    <div className="wa-flex flex-col">
      <ReactPlayer
        url="https://vimeo.com/755009873"
        height={window.innerHeight - (window.innerHeight * 10) / 100}
        width={window.innerWidth}
        playing={true}
        controls={true}
        onEnded={() => handleSkipVideo()}
      />
      <FormButton className="mt-8" onClick={handleSkipVideo} type="button">
        Skip Video
      </FormButton>
    </div>
  )
}

export default IntroVideo
