import { BasicHeader, ResetPassword } from '../../components'
import { ResetPasswordData } from '../../components/ResetPassword/schema'
import { getQueryParameter } from '../../helper/urlHelper'
import AuthService from '../../services/AuthService'
import { ResetPassData } from '../../services/interfaces/auth'

const ResetPasswordPage = () => {
  const email = getQueryParameter('email')
  let token = getQueryParameter('token')
  token = token?.replaceAll(' ', '+')

  const handleResetPassword = (values: ResetPasswordData) => {
    const resetPasswordData: ResetPassData = {
      email: email ?? '',
      password: values.password ?? '',
      token: token ?? '',
    }
    return AuthService.resetPass(resetPasswordData)
  }
  return (
    <div className="relative">
      <div className="default-bg forgotPassword-bg">
        <BasicHeader />
        <ResetPassword onSubmit={handleResetPassword} />
      </div>
    </div>
  )
}

export default ResetPasswordPage
