import { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleRight,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons'

import { Pages } from '../../../services/interfaces/admin'
interface IProps {
  pages: Pages
}
const Pagination: FC<IProps> = ({ pages }) => {
  const pageNumber = pages.currentPage
  const totalPageNumber = pages.totalPage

  const handlePageNumberChange = (num: number) => {
    if (0 < num && num <= totalPageNumber) {
      pages.setPageOptions({
        ...pages.options,
        PageNumber: num,
        PageSize: pages.pageSize,
      })
    }
  }

  const numbers = []

  for (let i = pageNumber - 2; i <= pageNumber + 2; i++) {
    if (i > 0 && i <= totalPageNumber) {
      numbers.push(
        <button
          key={i}
          className={`h-8 border-2 border-r-0 border-secondary w-8 ${
            i === pageNumber && 'bg-secondary text-white'
          }`}
          onClick={() => handlePageNumberChange(i)}
        >
          {i}
        </button>
      )
    }
  }

  return (
    <>
      {
        <div className="flex bg-white rounded-lg">
          <button
            onClick={() => handlePageNumberChange(1)}
            className="h-8 border-2 border-r-0 border-secondary px-4 rounded-l-lg hover:bg-secondary hover:text-white active:bg-secondary active:text-white focus:bg-white focus:border-secondary focus:text-black"
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </button>
          <button
            className="h-8 border-2 border-r-0 border-secondary w-8 hover:bg-secondary hover:text-white active:bg-secondary active:text-white focus:bg-white focus:border-secondary focus:text-black
      "
            onClick={() => handlePageNumberChange(pageNumber - 1)}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          {numbers}
          <button
            className="h-8 border-2 border-r-0 border-secondary w-8 hover:bg-secondary hover:text-white active:bg-secondary active:text-white focus:bg-white focus:border-secondary focus:text-black
      "
            onClick={() => handlePageNumberChange(pageNumber + 1)}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
          <button
            onClick={() => handlePageNumberChange(totalPageNumber)}
            className="h-8 border-2 border-secondary px-4 rounded-r-lg hover:bg-secondary hover:text-white active:bg-secondary active:text-white focus:bg-white focus:border-secondary focus:text-black"
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </button>
        </div>
      }
    </>
  )
}

export default Pagination
