import { AxiosRequestHeaders } from 'axios'
import { useEffect, useState } from 'react'

import { getAuthorisationToken } from '../../helper/mapHelper'
import { Header } from '../../components'
import {
  PageLinks,
  ErrorCodes,
  ErrorMessages,
  HeaderName,
} from '../../helper/enum'
import { UserAlertHistory } from '../../services/interfaces/user'
import useAuth from '../../hooks/useAuth'
import UserService from '../../services/UserService'
import { FrontEnd } from '../../components/Header/HeaderList'

const HistoryPage = () => {
  const [historyData, setHistoryData] = useState<UserAlertHistory[] | null>(
    null
  )
  const [noDataFoundMessage, setNoDataFoundMessage] = useState<string | null>(
    null
  )

  const authContext = useAuth()
  const userId: string = authContext?.user?.id ?? ''
  const authToken: AxiosRequestHeaders | null = getAuthorisationToken()

  useEffect(() => {
    if (authToken && userId) {
      UserService.getAlertHistory(authToken, userId)
        .then((response) => {
          setHistoryData(response.data)
        })
        .catch((error) => {
          console.log(error)
          if (error.code && error.code === ErrorCodes.NoDataFound) {
            setNoDataFoundMessage(ErrorMessages.HistoryNoDataFound)
          }
        })
    }
  }, [])

  return (
    <div className="default-bg register-success-bg">
      <Header
        currentPageName={HeaderName.History}
        page={PageLinks.History}
        header={FrontEnd}
      />
      <div className="font-poppins md:max-w-sm md:pl-20">
        <div className="history-container">
          {historyData ? (
            historyData.map((history, index) => (
              <div
                key={index}
                className="flex items-start flex-col mb-8 text-base leading-9"
              >
                <div className="text-black font-normal leading-6">
                  <div>{history.site}</div>
                  <div className="-mt-0.5">{history.alertLevel}</div>
                </div>
                <div className="text-xss leading-4 font-normal md:text-xs mt-2">
                  {history.content}
                </div>
                <div className="text-xss leading-4 font-normal md:text-xs mt-2">
                  Delivered At: {history.deliveredAt}
                </div>
              </div>
            ))
          ) : (
            <div className="flex items-start flex-col mb-8 text-base leading-9">
              <div className="text-info font-semibold">
                <div>{noDataFoundMessage}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HistoryPage
