import SpinnerIcon from './Icon/Spinner'

interface IProps {
  height?: string
  text?: string
}

const Loading: React.FC<IProps> = ({ height = 'h-full', text = 'Loading' }) => {
  return (
    <div className={`wa-flex w-full ${height}`}>
      <SpinnerIcon className="animate-spin" />
      <span className={`text-gray-600 ${text ? "ml-2" : "ml-0"}`}>{text}</span>
    </div>
  )
}

export default Loading
