import { makeAutoObservable, runInAction } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import {
  alertLevelsDefaults,
  emptySites,
  emptySite,
  SitePageSize,
} from '../../helper/constants'
import utilities from '../../helper/utilities'
import agent from '../api/agent'
import { PageOptions, SiteData, Sites } from '../interfaces/admin'
import { Row, Rows } from '../interfaces/common'
import { SiteStatus } from '../../helper/enum'
import { CreateSiteRequest, UpdateSiteRequest } from '../interfaces/site'

export default class SiteStore {
  sites: Sites | undefined = undefined
  selectedSite: SiteData | undefined
  siteOptions: PageOptions = {
    ...alertLevelsDefaults,
    PageSize: SitePageSize,
  }
  newSite: SiteData = emptySite

  isLoading = true

  constructor() {
    makeAutoObservable(this)

    makePersistable(this, {
      name: 'SiteStore',
      properties: ['sites', 'siteOptions'],
      storage: window.sessionStorage,
    })
  }

  loadSites = async (options: PageOptions | undefined = undefined) => {
    try {
      options = options || { ...alertLevelsDefaults, PageSize: SitePageSize }
      const result = await agent.Site.getSites({ params: options })
      this.setSites(result)
    } catch (error) {
      this.setSites(emptySites)
    } finally {
      this.setIsLoading(false)
    }
  }

  setSites = (sites: Sites) => {
    this.sites = sites
  }

  setSelectedSite = (id: string) => {
    const selectedSite = this.sites?.data.find((site) => site.id === id)
    this.selectedSite = selectedSite
  }

  setNewSite = () => {
    this.newSite = emptySite
  }

  setSiteOptions = (options: PageOptions) => {
    this.siteOptions = options
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  getTableRows: () => Rows = () => {
    const newSites: Rows = { rows: [] }
    if (this.sites) {
      this.sites.data.forEach((site) => {
        const row: Row = { id: site.id, data: [] }
        row.data.push(utilities.getTextAddOn(site.name))
        row.data.push(utilities.getTextAddOn(site.description))
        row.data.push(
          utilities.getTextAddOn(
            site.status ? SiteStatus.Active : SiteStatus.Inactive
          )
        )
        row.data.push({
          addOnContent: utilities.getOpenModalAddOn('Edit'),
          addOnOnClick: (id: string) => {
            this.setSelectedSite(id)
          },
        })
        newSites.rows.push(row)
      })
    }
    return newSites
  }

  updateSiteInfo = (site: SiteData) => {
    if (site.id === '') {
      this.newSite = site
    } else {
      this.selectedSite = site
    }
  }

  saveSite = async () => {
    if (this.selectedSite) {
      const updateSiteData: UpdateSiteRequest = {
        id: this.selectedSite.id,
        description: this.selectedSite.description,
        status: this.selectedSite.status,
        coordinates: this.selectedSite.coordinates,
        name: this.selectedSite.name,
      }

      await agent.Site.updateSite(updateSiteData)

      runInAction(() => {
        if (this.sites) {
          this.sites.data = this.sites.data.map((site) => {
            if (site.id === this.selectedSite?.id) {
              return this.selectedSite
            }
            return site
          })
        }
      })
    } else {
      const newSiteData: CreateSiteRequest = {
        description: this.newSite.description,
        status: this.newSite.status,
        coordinates: this.newSite.coordinates,
        name: this.newSite.name,
      }
      await agent.Site.createSite(newSiteData)
    }
  }
}
