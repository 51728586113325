import { AxiosRequestHeaders, AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useJsApiLoader } from '@react-google-maps/api'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'

import IntroVideo from './IntroVideo'
import Maps from '../../components/Maps/Maps'
import MapService from '../../services/MapService'
import Tutorial from './Tutorial'
import useAuth from '../../hooks/useAuth'
import useUserSubscription from '../../hooks/useUserSubscription'
import { getAuthorisationToken } from '../../helper/mapHelper'
import { Header } from '../../components'
import { Loading } from '../../components/UI'
import { HeaderName, PageLinks } from '../../helper/enum'
import { UserSubscriptionSaveSuccessMessage } from '../../helper/constants'
import {
  UserSubscription,
  SitesWithAlertLevel,
  SubscriptionDetails,
} from '../../services/interfaces/map'
import { FrontEnd } from '../../components/Header/HeaderList'
import { isAdmin } from '../../helper/authHelper'

const Alerts = () => {
  const authContext = useAuth()
  const userSubscriptionContext = useUserSubscription()
  const navigate = useNavigate()
  const IsAdmin = isAdmin(authContext.user?.roles)

  const userId: string = authContext?.user?.id ?? ''

  if (authContext.user === null) {
    navigate(PageLinks.Login)
  }

  const isTermsComplete = authContext?.user?.skipTermsAndConditions
  if (!isTermsComplete) {
    navigate(PageLinks.TermsAndConditions)
  }

  const [sites, setSites] = useState<SitesWithAlertLevel[] | null>(null)

  const authToken: AxiosRequestHeaders | null = getAuthorisationToken()
  const isVideoTutorialComplete = authContext?.user?.skipVideo
  const isTutorialComplete = authContext?.user?.skipTutorial
  useEffect(() => {
    if (authToken) {
      MapService.getSitesWithAlertLevels(authToken)
        .then((response: AxiosResponse<SitesWithAlertLevel[]>) => {
          setSites(response.data)
        })
        .catch((error) => {
          const errorDetails = error.toJSON()
          toast.error(errorDetails.message)
        })

      MapService.getUserAlertSubscriptions(authToken, userId)
        .then((response: AxiosResponse<SubscriptionDetails[]>) => {
          userSubscriptionContext?.setUserSubscription({
            userId: userId,
            subscriptions: response.data,
          })
        })
        .catch((error) => {
          const errorDetails = error.toJSON()
          console.log(errorDetails.message)

          userSubscriptionContext?.setUserSubscription({
            userId: userId,
            subscriptions: [],
          })
        })
    }
  }, [])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? '',
  })

  if (!isLoaded || sites === null) {
    return (
      <div className="h-screen">
        <Loading />
      </div>
    )
  }

  const onSave = (subscriptions: SubscriptionDetails[]) => {
    if (authToken) {
      const userSubscription: UserSubscription = {
        userId: authContext?.user?.id ?? '',
        subscriptions,
      }
      MapService.updateUserAlertSubscriptions(authToken, userSubscription)
        .then(() => {
          toast.success(UserSubscriptionSaveSuccessMessage)
        })
        .catch((error) => {
          const { message } = error.toJSON()
          toast.error(message)
        })
    }
  }

  const props = {
    authContext,
    authToken,
  }

  const filterSites = (sites: SitesWithAlertLevel[]) => {
    if (!IsAdmin && authContext.user?.type === 'public') {
      const filteredSites = sites.filter((site) => {
        return site.type === 'public'
      })
      return filteredSites
    }
    return sites
  }

  if (!isVideoTutorialComplete) {
    return <IntroVideo />
  }

  return (
    <>
      {isTutorialComplete ? (
        <>
          <Header
            page={PageLinks.Alert}
            header={FrontEnd}
            currentPageName={HeaderName.Alert}
          />
          <Maps sites={filterSites(sites)} onSave={onSave} />
        </>
      ) : (
        <Tutorial {...props} />
      )}
    </>
  )
}

export default Alerts
