import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { Header } from '../../../components'
import { AdminPortal } from '../../../components/Header/HeaderList'
import { Loading, Table, Modal } from '../../../components/UI'
import {
  UserSaveSuccessMessage,
  UsersPageSize,
  UsersTableColumn,
} from '../../../helper/constants'
import {
  HeaderName,
  PageLinks,
  UserStatus,
  UserType,
} from '../../../helper/enum'
import { Pages, TableColumnType } from '../../../services/interfaces/admin'
import { useStore } from '../../../services/stores/store'

const AdminUsersPage = () => {
  const { userStore } = useStore()
  const {
    users,
    selectedUser,
    usersOptions,
    isLoading,
    loadUsers,
    setSelectedUser,
    setUsersOptions,
    getTableRows,
    updateSelectedUserInfo,
    saveSalectedUser,
  } = userStore
  const columns: TableColumnType[] = UsersTableColumn

  const pages: Pages = {
    currentPage: users?.currentPage || 1,
    totalPage: users?.totalPage || 1,
    pageSize: UsersPageSize,
    options: usersOptions,
    setPageOptions: setUsersOptions,
  }

  const getModalBody = () => {
    if (!selectedUser) {
      return <></>
    }

    return (
      <div>
        <div className="inline-flex mb-3 w-full">
          <label className="w-[20%]" htmlFor="status">
            Status
          </label>
          <select
            name="status"
            className="px-2 border-2 rounded-md bg-black opacity-80 w-[80%]"
            value={
              selectedUser.status ? UserStatus.Active : UserStatus.Inactive
            }
            onChange={(event) => {
              updateSelectedUserInfo({
                ...selectedUser,
                status: event.target.value === UserStatus.Active,
              })
            }}
          >
            {Object.values(UserStatus).map((value, index) => (
              <option key={index} label={value} value={value} />
            ))}
          </select>
        </div>
        <div className="inline-flex mb-3 w-full">
          <label className="w-[20%]" htmlFor="email">
            Email
          </label>
          <input
            name="email"
            className="px-3 border-2 rounded-md bg-black opacity-80 w-[80%]"
            value={selectedUser.email}
            readOnly
          />
        </div>
        <div className="inline-flex mb-3 w-full">
          <label className="w-[20%]" htmlFor="mobile">
            Mobile
          </label>
          <input
            name="mobile"
            className="px-3 border-2 rounded-md bg-black opacity-80 w-[80%]"
            value={selectedUser.mobileNumber}
            readOnly
          />
        </div>
        <div className="inline-flex mb-3 w-full">
          <label className="w-[20%]" htmlFor="type">
            Type
          </label>
          <select
            name="type"
            className="px-2 border-2 rounded-md bg-black opacity-80 w-[80%]"
            value={selectedUser.type}
            onChange={(event) => {
              updateSelectedUserInfo({
                ...selectedUser,
                type: event.target.value,
              })
            }}
          >
            {Object.values(UserType).map((value, index) => (
              <option key={index} label={value} value={value.toLowerCase()} />
            ))}
          </select>
        </div>
      </div>
    )
  }

  const handleClose = () => {
    setSelectedUser('')
  }

  const handleSave = async () => {
    try {
      await saveSalectedUser()

      toast.success(UserSaveSuccessMessage)
    } catch (error) {
      console.log('Error saving user changes.')
    } finally {
      setSelectedUser('')
    }
  }

  useEffect(() => {
    loadUsers(usersOptions)
  }, [usersOptions])

  if (isLoading) {
    return (
      <div className="h-screen">
        <Loading />
      </div>
    )
  }

  return (
    <div>
      <Header
        page={PageLinks.AdminUsers}
        header={AdminPortal}
        currentPageName={HeaderName.AdminUsers}
      />
      <div className="flex flex-col mb-5">
        <span className="mt-20"></span>
      </div>
      <Table columns={columns} rows={getTableRows()} pages={pages} />
      <Modal
        header={
          <h5
            className="text-xl font-medium leading-normal text-white"
            id="ht-modal-label"
          >
            {`${selectedUser?.firstName || ''} ${selectedUser?.lastName || ''}`}
          </h5>
        }
        body={getModalBody()}
        footer={
          <button
            type="button"
            className="inline-block px-6 py-2.5 bg-secondary text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:bg-opacity-90 hover:shadow-lg active:bg-opacity-80 active:shadow-lg transition duration-150 ease-in-out ml-1"
            data-bs-dismiss="modal"
            onClick={() => {
              handleSave()
            }}
          >
            Save
          </button>
        }
        onClose={handleClose}
      ></Modal>
    </div>
  )
}

export default observer(AdminUsersPage)
