import { Navigate, Outlet, Route, Routes } from 'react-router-dom'

import {
  AlertPage,
  ConfirmEmailPage,
  ForgotPasswordPage,
  HistoryPage,
  AboutPage,
  IntroVideoPage,
  LoginPage,
  ManageProfilePage,
  RegisterPage,
  RegistrationSuccessPage,
  ResetPasswordPage,
  TermsAndConditionsPage,
  AdminCommunicatePage,
  AdminSendNotificationPage,
  AdminTemplatesPage,
  AdminUsersPage,
  AdminEditTemplatePage,
  AdminHistoryPage,
  MaintenancePage,
  SitePage,
} from './pages'
import { AuthProvider } from './context/AuthProvider'
import { PageLinks } from './helper/enum'
import { RequireAuth } from './components'
import Toast from './components/UI/Toast/Toast'

import './styles/global.css'
import { UserSubscriptionProvider } from './context/UserSubscriptionProvider'

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <div className="h-full w-full">
          <Toast />
          <Routes>
            <Route element={<Outlet />}>
              <Route
                path="/"
                element={<Navigate to={PageLinks.Login} replace />}
              />
              <Route path={PageLinks.Login} element={<LoginPage />} />
              <Route path={PageLinks.Register} element={<RegisterPage />} />
              <Route
                path={PageLinks.RegisterSuccess}
                element={<RegistrationSuccessPage />}
              />
              <Route
                path={PageLinks.ConfirmEmail}
                element={<ConfirmEmailPage />}
              />
              <Route
                path={PageLinks.ForgotPassword}
                element={<ForgotPasswordPage />}
              />
              <Route
                path={PageLinks.ResetPassword}
                element={<ResetPasswordPage />}
              />
              <Route
                path={PageLinks.Maintenance}
                element={<MaintenancePage />}
              />

              <Route element={<RequireAuth />}>
                <Route
                  path={PageLinks.Alert}
                  element={
                    <UserSubscriptionProvider>
                      <AlertPage />
                    </UserSubscriptionProvider>
                  }
                />
                <Route
                  path={PageLinks.IntroVideo}
                  element={<IntroVideoPage />}
                />
                <Route
                  path={PageLinks.TermsAndConditions}
                  element={<TermsAndConditionsPage />}
                />
                <Route
                  path={PageLinks.Profile}
                  element={<ManageProfilePage />}
                />
                <Route path={PageLinks.History} element={<HistoryPage />} />
                <Route path={PageLinks.About} element={<AboutPage />} />
                <Route path={PageLinks.FAQ} element={<AboutPage />} />

                <Route
                  path={PageLinks.AdminCommunicate}
                  element={<AdminCommunicatePage />}
                />
                <Route
                  path={PageLinks.AdminSendNotification}
                  element={<AdminSendNotificationPage />}
                />
                <Route
                  path={PageLinks.AdminTemplates}
                  element={<AdminTemplatesPage />}
                />
                <Route
                  path={PageLinks.AdminUsers}
                  element={<AdminUsersPage />}
                />
                <Route
                  path={PageLinks.AdminEditTemplate}
                  element={<AdminEditTemplatePage />}
                />
                <Route
                  path={PageLinks.AdminHistory}
                  element={<AdminHistoryPage />}
                />
                <Route path={PageLinks.Site} element={<SitePage />} />
              </Route>
            </Route>
          </Routes>
        </div>
      </div>
    </AuthProvider>
  )
}

export default App
