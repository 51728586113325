import React, { FC, ReactElement } from 'react'

interface IProps {
  accordion: { title: string; body: string | ReactElement }
  index: number | null
}

const AccordionItem: FC<IProps> = ({ accordion, index }) => {
  return (
    <div className="accordion-item  !rounded-none bg-gray-200/75  mb-1">
      <h2 className="accordion-header mb-0" id={`flush-heading-${index}`}>
        <button
          className="accordion-button
        collapsed
        relative
        flex
        items-center
        w-full  
        py-4
        px-5
        text-base text-gray-800 text-left
        !bg-inherit
        border-0
        !rounded-none
        !shadow-none
        transition
        focus:outline-none 
        focus:!bg-inherit"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#flush-collapse-${index}`}
          aria-expanded="false"
          aria-controls={`flush-collapse-${index}`}
        >
          {accordion.title}
        </button>
      </h2>
      <div
        id={`flush-collapse-${index}`}
        className="accordion-collapse border-0 collapse"
        aria-labelledby={`flush-heading-${index}`}
        data-bs-parent="#accordionFlushExample"
      >
        <div className="accordion-body py-4 px-5">{accordion.body}</div>
      </div>
    </div>
  )
}

export default AccordionItem
