import { IconType } from './types'

const CheckIcon: React.FC<IconType> = ({ colour = '#fff', ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 0C4.937 0 0 4.938 0 11c0 6.063 4.938 11 11 11 6.063 0 11-4.938 11-11 0-6.063-4.938-11-11-11zm0 2c4.98 0 9 4.02 9 9s-4.02 9-9 9-9-4.02-9-9 4.02-9 9-9zm5.406 5.188L10 13.563 6.719 10.28 5.28 11.72l4 4 .719.687.719-.687 7.094-7.125-1.407-1.406z"
        fill={colour}
      />
    </svg>
  )
}

export default CheckIcon
