import * as yup from 'yup'

export const resetPasswordScehma = yup.object({
    password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password entered is too short')
    .max(50, 'Password entered is too big')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.{8,})/,
      'Enter password matching below criterias'
    ),
})

export type ResetPasswordData = yup.InferType<typeof resetPasswordScehma>