import { createContext, useState } from 'react'
import { UserSubscriptionContextType, UserSubscription } from '../services/interfaces/map'

type UserSubscriptionContextProviderProps = {
  children: React.ReactNode
}

const initialState = {} as UserSubscriptionContextType

export const UserSubscriptionContext = createContext(initialState)

export const UserSubscriptionProvider = ({ children }: UserSubscriptionContextProviderProps) => {
  const [userSubscription, setUserSubscription] = useState<UserSubscription | null>(null)

  return (
    <UserSubscriptionContext.Provider value={{ userSubscription, setUserSubscription }}>
      {children}
    </UserSubscriptionContext.Provider>
  )
}