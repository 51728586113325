import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { SessionExpiredMessage } from '../../helper/constants'
import { PageLinks } from '../../helper/enum'
import { UserData } from '../interfaces/auth'

export default class CommonStore {
  token: string | null = null
  userName: string | null = null
  user: UserData | null = null
  tokenExpirationTimeout: NodeJS.Timeout | null = null

  constructor() {
    makeAutoObservable(this)

    makePersistable(this, {
      name: 'CommonStore',
      properties: ['token', 'userName', 'user'],
      storage: window.sessionStorage,
    })
  }

  setToken = (token: string | null) => {
    this.token = token
  }

  setUserName = (userName: string | null) => {
    this.userName = userName
  }

  setUser = (user: UserData | null) => {
    this.user = user
    user ? this.startTokenExpirationTimer() : this.stopTokenExpirationTimer()
  }

  private startTokenExpirationTimer = () => {
    if (this.user) {
      const timeout = parseInt(this.user.expireIn) * 1000
      this.tokenExpirationTimeout = setTimeout(() => {
        alert(SessionExpiredMessage)
        window.location.href = PageLinks.Login
      }, timeout)
    }
  }

  private stopTokenExpirationTimer = () => {
    this.tokenExpirationTimeout = null
  }
}
