interface IProps {
  currenstSlide: number
  totalSlides: number
  nextSlide: (slideId: number) => void
}

const Indicator: React.FC<IProps> = ({
  currenstSlide,
  totalSlides,
  nextSlide,
}) => {
  return (
    <div className="flex">
      {Array(totalSlides)
        .fill(1)
        .map((_, i) => (
          <div
            className={`dot ${
              currenstSlide === i ? 'opacity-100' : 'opacity-10'
            }`}
            key={i}
            onClick={() => nextSlide(i)}
          />
        ))}
    </div>
  )
}

export default Indicator
