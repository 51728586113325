import { Header } from '../../../components'
import { AdminPortal } from '../../../components/Header/HeaderList'
import { MeasurementType, PageLinks } from '../../../helper/enum'
import Grid from '../../../components/UI/Grid/Grid'
import useAuth from '../../../hooks/useAuth'
import { Navigate, useNavigate } from 'react-router-dom'
import { isAdmin } from '../../../helper/authHelper'
import { useEffect } from 'react'
import { useStore } from '../../../services/stores/store'
import { observer } from 'mobx-react-lite'
import Loading from '../../../components/UI/Loading'
import { alertLevelsDefaults } from '../../../helper/constants'

const AdminCommunicatePage = () => {
  const { alertLevelStore } = useStore()
  const { alertLevels, isLoading, loadAlertLevels, selectAlertLevel } =
    alertLevelStore
  const navigate = useNavigate()
  const authContext = useAuth()
  const IsAdmin = isAdmin(authContext.user?.roles)

  const editNotification = (id: string) => {
    selectAlertLevel(id)
    navigate(PageLinks.AdminSendNotification)
  }

  useEffect(() => {
    loadAlertLevels(alertLevelsDefaults)
  }, [])

  if (isLoading)
    return (
      <div className="h-screen">
        <Loading />
      </div>
    )

  return (
    <div>
      {IsAdmin ? (
        <div className=" overflow-auto h-screen">
          <Header
            page={PageLinks.AdminCommunicate}
            header={AdminPortal}
            currentPageName="Communicate"
          />

          <div className=" flex justify-center">
            <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-8 mt-32 max-w-7xl p-5">
              {alertLevels &&
                alertLevels.data.map((levelSite, index) => {
                  return (
                    <Grid
                      key={index}
                      onClick={() => {
                        editNotification(levelSite.id)
                      }}
                      siteName={levelSite.measurementSite}
                      level={
                        levelSite.measurementSiteType ===
                        MeasurementType.WaterLevel
                          ? levelSite.name
                          : levelSite.measurementSiteType
                      }
                      subscribers={levelSite.subscribedUsers.length}
                    />
                  )
                })}
            </div>
          </div>
        </div>
      ) : (
        <Navigate to={PageLinks.Login} />
      )}
    </div>
  )
}

export default observer(AdminCommunicatePage)
