import { FC } from 'react'

import { Pages, TableColumnType } from '../../../services/interfaces/admin'
import { Rows } from '../../../services/interfaces/common'
import Pagination from './Pagination'
import Search from './Search'
import TableHeadItem from './TableHeadItem'
import TableRow from './TableRow'

interface IProps {
  columns: TableColumnType[]

  rows: Rows
  pages: Pages
}

const Table: FC<IProps> = ({ columns, rows, pages }) => {
  return (
    <>
      <div className="flex flex-col justify-center my-8">
        <div className="overflow-x-auto w-full">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <Search pages={pages} columns={columns} />
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="border-b">
                  <tr>
                    {columns.map((columnItem, index) => {
                      return (
                        <TableHeadItem
                          item={columnItem}
                          pages={pages}
                          key={index}
                        />
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {rows &&
                    rows.rows.map((row, index) => {
                      return <TableRow row={row} key={index} />
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className=" self-center mt-5">
          <Pagination pages={pages} />
        </div>
      </div>
    </>
  )
}

export default Table
