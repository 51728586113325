import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const getTextAddOn = (text: string) => {
  return { addOnContent: text, addOnOnClick: undefined }
}

const getOpenModalAddOn = (text: string) => {
  return (
    <button
      type="button"
      className="bg-transparent"
      data-bs-toggle="modal"
      data-bs-target="#ht-modal"
    >
      {text}
    </button>
  )
}

const getIconAddOn = (icon: IconDefinition) => {
  return (
    <FontAwesomeIcon
      icon={icon}
      className="text-lg"
      data-bs-toggle="modal"
      data-bs-target="#ht-modal"
    />
  )
}

const utilities = {
  getTextAddOn,
  getOpenModalAddOn,
  getIconAddOn,
}

export default utilities
