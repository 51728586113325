interface IProps {
  name: string
  isActive: boolean
  className: string
  onClick: () => void
}

const SubscriptionItem: React.FC<IProps> = ({
  className,
  isActive,
  name,
  onClick,
}) => {
  return (
    <span
      className={`md:cursor-pointer btn-notif ${className}${
        isActive ? ' btn-notif-active' : ''
      }`}
      onClick={() => onClick()}
    >
      {name}
    </span>
  )
}

export default SubscriptionItem
