import { AxiosResponse } from 'axios'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useState } from 'react'
import toast from 'react-hot-toast'

import FormInput from '../UI/Form/Input'
import { FormButton } from '../UI'
import { resetPasswordScehma, ResetPasswordData } from './schema'
import { ServerErrors } from '../../services/interfaces/common'
import {
  ResetPasswordSuccessMessage,
  GenericErrorMessage,
} from '../../helper/constants'

interface IProps {
  onSubmit: (values: ResetPasswordData) => Promise<AxiosResponse>
}

export const ResetPassword: React.FC<IProps> = ({ onSubmit }) => {
  const [err, setError] = useState<string>('')
  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik<ResetPasswordData>({
      initialValues: {
        password: '',
      },
      validationSchema: resetPasswordScehma,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: (formData: ResetPasswordData, { setSubmitting }) => {
        const userData: ResetPasswordData = formData
        onSubmit(userData)
          .then(() => {
            setError('')
            toast.success(ResetPasswordSuccessMessage)
          })
          .catch((error: ServerErrors) => {
            let serverError = ''

            if (Array.isArray(error.error)) {
              serverError = error.error[0].description
            } else {
              serverError = error.error
            }

            toast.error(serverError ?? GenericErrorMessage)
            setError(serverError ?? GenericErrorMessage)
          })
          .finally(() => setSubmitting(false))
      },
    })

  return (
    <div className="p-5">
      <div className="container">
        <div className="absolute p-5 w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="max-w-md mx-auto">
            <div className="text-center">
              <h1 className="text-4xl">Reset your password</h1>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="py-4 px-8">
                <div className="mb-4">
                  <FormInput
                    className="form-input"
                    id="password"
                    type="password"
                    placeholder="New Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={touched.password ? errors.password : ''}
                  />
                  <p className="text-grey text-xs mt-1 text-justify">
                    Use 8 or more characters with a mix of letters, numbers
                    &amp; symbols
                  </p>
                </div>

                <div className="text-center">
                  {err && <div className="text-sm text-error">{err}</div>}
                  <div className="mt-8">
                    <FormButton type="submit" className="btn-primary">
                      Save Password
                    </FormButton>
                    <p className="text-info mt-4">
                      Back to{' '}
                      <Link to="/login" className="underline hover:text-black">
                        Login
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ResetPassword
