import Close from '../resources/icons/close.png'
import FlowInactive from '../resources/icons/flow-inactive.png'
import FlowActive from '../resources/icons/flow-active.png'
import PlannedInactive from '../resources/icons/planned-inactive.png'
import PlannedActive from '../resources/icons/planned-active.png'
import SpillInactive from '../resources/icons/spill-inactive.png'
import SpillActive from '../resources/icons/spill-active.png'
import WaterLevelInactive from '../resources/icons/water-level-inactive.png'
import WaterLevelActive from '../resources/icons/water-level-active.png'
import ManualInactive from '../resources/icons/manual-inactive.png'
import ManualActive from '../resources/icons/manual-active.png'
import HydroLogo from '../resources/logos/hydro-logo.png'
import HydroLogoReversed from '../resources/logos/hydro-logo-reversed.png'
import WaterAlertNotice from '../resources/logos/wateralerts-notice.png'

const Icons = {
  Close,
  FlowInactive,
  FlowActive,
  PlannedInactive,
  PlannedActive,
  SpillInactive,
  SpillActive,
  WaterLevelInactive,
  WaterLevelActive,
  ManualActive,
  ManualInactive,
}

export const Logos = {
  HydroLogo,
  HydroLogoReversed,
  WaterAlertNotice
}

export default Icons
