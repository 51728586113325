import { Toaster } from 'react-hot-toast'
import { theme } from 'twin.macro'

import { CheckIcon, InfoIcon } from '../../UI'

const Toast = () => {
  return (
    <Toaster
      position="top-center"
      reverseOrder={false}
      toastOptions={{
        style: {
          border: 'none',
        },
        success: {
          style: {
            background: theme`colors.success`,
            minWidth: 'fit-content',
            color: theme`colors.black`,
          },
          duration: 2000,
          icon: (
            <CheckIcon height="22" width="22" colour={theme`colors.white`} />
          ),
        },
        error: {
          duration: 2000,
          style: {
            background: theme`colors.error`,
            minWidth: 'fit-content',
            color: theme`colors.white`,
          },
          icon: (
            <InfoIcon height="22" width="22" colour={theme`colors.white`} />
          ),
        },
      }}
    />
  )
}

export default Toast
