import React, { FC } from 'react'

interface IProps {
  header?: JSX.Element | string
  body?: JSX.Element | string
  footer?: JSX.Element
  onClose?: () => void
}

const Modal: FC<IProps> = ({ header, body, footer, onClose }) => {
  return (
    <div
      className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
      id="ht-modal"
      tabIndex={-1}
      aria-labelledby="ht-modal"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-black opacity-80 bg-clip-padding rounded-md outline-none text-current">
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b-0 border-gray-200 rounded-t-md">
            {header}
            <button
              type="button"
              className="btn-close box-content w-4 h-4 p-1 !text-white border-none rounded-none self-start opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-white hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                onClose && onClose()
              }}
            ></button>
          </div>
          <div className="modal-body relative p-4 text-white">{body}</div>
          <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 border-t-0 border-gray-200 rounded-b-md">
            {footer}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
