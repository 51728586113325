import { ButtonVariant, MeasurementType } from '../../helper/enum'
import { FormButton } from '../UI'
import {
  SitesWithAlertLevel,
  SubscriptionDetails,
} from '../../services/interfaces/map'
import Default from './AlertTypeDefault'
import useMarker from './useMarker'
import WaterLevel from './AlertTypeWaterLevel'
import useUserSubscription from '../../hooks/useUserSubscription'

interface IProps {
  data: SitesWithAlertLevel
  onClose: () => void
  onSave: (userSubscriptions: SubscriptionDetails[]) => void
  setSnapshot: (userSubscriptions: SubscriptionDetails[]) => void
}

const MarkerInfo: React.FC<IProps> = ({
  data,
  onClose,
  onSave,
  setSnapshot,
}) => {
  const { alertLevels, measurementType, isAutomated } = data
  const userSubscriptionContext = useUserSubscription()

  const subsDetails: SubscriptionDetails[] = alertLevels.map((alert) => ({
    alertlevelId: alert.id,
    subscriptionTypes: [],
  }))

  /*
   * Check if userSubscription is available
   * update local state with users subscription
   */
  userSubscriptionContext.userSubscription?.subscriptions?.forEach((subs) => {
    const index = subsDetails.findIndex(
      (x) => x.alertlevelId === subs.alertlevelId
    )
    subsDetails[index] = {
      ...subsDetails[index],
      subscriptionTypes: subs.subscriptionTypes,
    }
  })

  const {
    activeLevel,
    subscriptions,
    isActiveSubscription,
    hasSubscription,
    setActiveLevel,
    updateState,
  } = useMarker(subsDetails)

  const props = {
    activeLevel,
    hasSubscription,
    isActiveSubscription,
    setActiveLevel,
    updateState,
  }

  const onSubscriptionSave = () => {
    if (userSubscriptionContext.userSubscription) {
      let userSubscriptions = [
        ...userSubscriptionContext.userSubscription.subscriptions.filter(
          (userSubs) => {
            return (
              userSubs.subscriptionTypes &&
              userSubs.subscriptionTypes.length > 0
            )
          }
        ),
        ...subscriptions.filter((subs) => {
          return subs.subscriptionTypes && subs.subscriptionTypes.length > 0
        }),
      ]

      const setObj = new Set()

      userSubscriptions = userSubscriptions.reduce<SubscriptionDetails[]>(
        (acc, item) => {
          if (!setObj.has(item.alertlevelId)) {
            setObj.add(item.alertlevelId)
            acc.push(item)
          }
          return acc
        },
        []
      )

      userSubscriptionContext.setUserSubscription({
        userId: userSubscriptionContext.userSubscription.userId,
        subscriptions: [...userSubscriptions],
      })

      onSave(subscriptions)
      setSnapshot(JSON.parse(JSON.stringify(subscriptions)))
      onClose()
    }
  }

  const getMarkerInfoTemplate = (): JSX.Element => {
    if (!isAutomated) {
      if (measurementType === MeasurementType.WaterLevel)
        return <WaterLevel data={data} {...props} />
      else return <Default data={data} {...props} />
    } else {
      switch (measurementType) {
        case MeasurementType.WaterLevel:
        case MeasurementType.Flow:
          return <WaterLevel data={data} {...props} />
        case MeasurementType.Spilling:
        case MeasurementType.Riparian:
          return <Default data={data} {...props} />
        default:
          return <></>
      }
    }
  }

  return (
    <>
      {getMarkerInfoTemplate()}
      <div className="text-center">
        <FormButton
          onClick={onSubscriptionSave}
          variant={ButtonVariant.Secondary}
          className="mt-8 mb-8 hover:opacity-80"
        >
          <div className="text-wa text-black">Save</div>
        </FormButton>
      </div>
    </>
  )
}

export default MarkerInfo
