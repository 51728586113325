import { Editor } from '@tinymce/tinymce-react'
import { Editor as TinyMCEEditor } from 'tinymce'
import { observer } from 'mobx-react-lite'
import { useRef } from 'react'
import { Header } from '../../../components'
import { AdminPortal } from '../../../components/Header/HeaderList'
import FormButton from '../../../components/UI/Form/Button'
import {
  MergeFieldList,
  TemplateSaveSuccessMessage,
} from '../../../helper/constants'
import { PageLinks } from '../../../helper/enum'
import { useStore } from '../../../services/stores/store'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const AdminEditTemplatePage = () => {
  const { alertLevelStore, templateStore } = useStore()
  const {
    selectedAlertLevel,
    updateSelectedAlertLevel,
    saveAlertLevelChanges,
  } = alertLevelStore
  const { saveTemplateChanges } = templateStore
  const navigate = useNavigate()

  const editorRef = useRef<TinyMCEEditor | null>(null)

  const handleSave = async () => {
    if (selectedAlertLevel) {
      try {
        await saveAlertLevelChanges()
        await saveTemplateChanges({
          id: selectedAlertLevel.templateId,
          name: selectedAlertLevel.templateName,
          email: selectedAlertLevel.emailTemplate,
        })

        toast.success(TemplateSaveSuccessMessage)
      } catch (error) {
        console.log('Error saving changes.')
      }
    }
    navigate(PageLinks.AdminTemplates)
  }

  const handleCancel = () => {
    navigate(PageLinks.AdminTemplates)
  }

  return (
    <div className="default-bg">
      <Header
        page={PageLinks.AdminTemplates}
        header={AdminPortal}
        currentPageName="Templates"
      />
      {selectedAlertLevel && (
        <div className="absolute w-screen mt-28">
          <div className="mb-2 px-4 text-lg">
            <div className="w-[150px] inline-flex">Site:</div>
            <p className="inline-flex w-[400px] text-lg" title="Site">
              {selectedAlertLevel.measurementSite}
            </p>
          </div>
          <div className="mb-2 px-4 text-lg">
            <div className="w-[150px] inline-flex">Name:</div>
            <input
              title="Name"
              placeholder="name"
              className="inline-flex w-[350px] mb-4 p2 mr-0  border-2 rounded"
              value={selectedAlertLevel.name}
              onChange={(event) =>
                updateSelectedAlertLevel({
                  ...selectedAlertLevel,
                  name: event.target.value,
                })
              }
            ></input>
          </div>
          <div className="w-full flex flex-col sm:flex-row flex-wrap sm:flex-nowrap py-8 flex-grow">
            <div className="w-[50%] flex-shrink flex-grow-0 px-4 border-r-2">
              <h2 className="font-semibold pb-2">Internal Instructions</h2>
              <textarea
                title="Instructions"
                placeholder="Instructions here..."
                className="w-full p-4 border-2 rounded h-[600px] resize-none"
                value={selectedAlertLevel.description ?? ''}
                onChange={(event) =>
                  updateSelectedAlertLevel({
                    ...selectedAlertLevel,
                    description: event.target.value,
                  })
                }
              />
            </div>
            <div className="w-full flex-grow px-4 border-x-2">
              <h2 className="font-semibold pb-2">Email Template</h2>
              <input
                title="Subject"
                placeholder="Subject"
                className="w-full mb-4 p-4  border-2 rounded"
                value={selectedAlertLevel.templateName}
                onChange={(event) =>
                  updateSelectedAlertLevel({
                    ...selectedAlertLevel,
                    templateName: event.target.value,
                  })
                }
              ></input>
              <Editor
                id="message-area"
                textareaName="MessageArea"
                tinymceScriptSrc={
                  process.env.REACT_APP_PUBLIC_URL + '/tinymce/tinymce.min.js'
                }
                value={selectedAlertLevel.emailTemplate}
                init={{
                  height: 525,
                  menubar: false,
                }}
                onInit={(evt, editor) => (editorRef.current = editor)}
                onEditorChange={(newMessage) => {
                  updateSelectedAlertLevel({
                    ...selectedAlertLevel,
                    emailTemplate: newMessage,
                  })
                }}
              />
            </div>
            <div className="w-[50%] flex-shrink flex-grow-0 px-4 border-l-2">
              <h2 className="font-semibold pb-2">MergeFields</h2>
              <ul className="overflow-hidden h-[600px]">
                {MergeFieldList.toArray().map((mergeField, index) => {
                  return (
                    <li className="my-4" key={index}>
                      <p
                        className="font-semibold hover:text-secondary cursor-pointer"
                        onClick={() =>
                          editorRef.current?.execCommand(
                            'mceInsertContent',
                            false,
                            mergeField.placeholder
                          )
                        }
                      >
                        {mergeField.placeholder}
                      </p>
                      <p>{mergeField.description}</p>
                    </li>
                  )
                })}
              </ul>
              <FormButton
                className="mt-2 float-right !w-24 !text-lg"
                onClick={() => {
                  handleSave()
                }}
                type="button"
              >
                Save
              </FormButton>
              <FormButton
                className="mt-2 mr-2 float-right !w-24 !text-lg bg-red-400"
                onClick={() => {
                  handleCancel()
                }}
                type="button"
              >
                Cancel
              </FormButton>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(AdminEditTemplatePage)
