import { FC } from 'react'
import { Link } from 'react-router-dom'

import { PageLinks } from '../../../helper/enum'
import { headerButtonList } from '../../Header/HeaderList'

interface IProps {
  text: headerButtonList
  navigateTo: PageLinks
  onClick?: () => void
}

const HeaderButton: FC<IProps> = ({ text, navigateTo, onClick }) => {
  return (
    <Link
      to={navigateTo}
      onClick={onClick}
      className="inline-block px-3 py-2 mx-4 bg-secondary !text-white text-md leading-tight rounded-lg shadow-md  hover:bg-opacity-90 hover:shadow-lg active:bg-opacity-80 active:shadow-lg transition duration-150 ease-in-out"
    >
      {text}
    </Link>
  )
}

export default HeaderButton
