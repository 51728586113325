import { useFormik } from 'formik'
import { AxiosResponse } from 'axios'
import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { FormButton, FormInput } from '../UI'
import { ILocationState } from '../../services/interfaces/common'
import { loginSchema, LoginUserData } from './schema'
import { PageLinks } from '../../helper/enum'
import { userStateName } from '../../helper/constants'
import useAuth from '../../hooks/useAuth'
import { store } from '../../services/stores/store'
import { isAdmin } from '../../helper/authHelper'

interface IProps {
  onLoginSubmit: (values: LoginUserData) => Promise<AxiosResponse>
}

const Login: React.FC<IProps> = ({ onLoginSubmit }) => {
  const userContext = useAuth()

  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as ILocationState
  const from = state?.from?.pathname || null

  const [err, setErr] = useState<string>('')

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik<LoginUserData>({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: loginSchema,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: async (formData: LoginUserData, { setSubmitting }) => {
        const userData: LoginUserData = formData
        await onLoginSubmit(userData)
          .then((result) => {
            sessionStorage.setItem(userStateName, JSON.stringify(result.data))
            userContext?.setUser(result.data)
            store.commonStore.setToken(result.data?.token)
            store.commonStore.setUser(result.data)

            store.commonStore.setUserName(
              `${result.data?.firstName} ${result.data?.lastName}`
            )

            navigate(
              from
                ? from
                : `${
                    isAdmin(result.data?.roles)
                      ? PageLinks.AdminCommunicate
                      : PageLinks.Alert
                  } `
            )
          })
          .catch((error) => {
            setSubmitting(false)
            setErr(error?.error)
          })
      },
    })

  return (
    <div className="p-5">
      <div className="container">
        <div className="absolute p-5 w-full top-[53%] md:top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="max-w-md mx-auto">
            <form onSubmit={handleSubmit}>
              <div className="py-4 px-8">
                <div className="mb-4">
                  <FormInput
                    className="form-input"
                    id="email"
                    type="email"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={touched.email ? errors.email : ''}
                  />
                </div>
                <div className="mb-4">
                  <FormInput
                    className="form-input"
                    id="password"
                    type="password"
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={touched.password ? errors.password : ''}
                  />
                </div>

                <div className="text-center">
                  {err && <div className="text-sm text-error">{err}</div>}
                  <p className="text-info mt-4">
                    <Link
                      to={PageLinks.ForgotPassword}
                      className="underline hover:text-black"
                    >
                      Forgot Password
                    </Link>
                  </p>
                  <div className="mt-8">
                    <FormButton type="submit" className="btn-primary">
                      Log in
                    </FormButton>
                    <p className="text-info mt-4">
                      Don’t have an account?{' '}
                      <Link
                        to={PageLinks.Register}
                        className="underline hover:text-black"
                      >
                        Register here
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
